<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            langkah: [],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var langkah = response_data_fix.konfig_template.find((konfig) => konfig.remark === "langkah");
                        self.langkah = JSON.parse(langkah.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        langkah: self.langkah,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addLangkah() {
            this.langkah.push({
                judul_langkah: null,
                hal: [{ isi_hal: null }],
            });
        },
        removeLangkah(index) {
            this.langkah.splice(index, 1);
        },
        addHall(index_langkah) {
            this.langkah[index_langkah].hal.push({
                isi_hal: null,
            });
        },
        removeHal(index_langkah, index_hal) {
            this.langkah[index_langkah].hal.splice(index_hal, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center">CEKLIS KONSULTASI PRA ASESMEN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tbody>
                <tr>
                    <td>Nama Kandidat</td>
                    <td>:</td>
                    <td>Tanggal</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td>Nama Asesor</td>
                    <td>:</td>
                    <td>Waktu</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td>Skema</td>
                    <td>: PELAKSANA PATOLOGI DAN TOKSIKOLOGI</td>
                    <td>Tempat</td>
                    <td>:</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <thead>
                <tr style="background-color: rgb(230, 230, 230)">
                    <th>Langkah</th>
                    <th>Hal-hal yang dilakukan</th>
                    <th style="width: 20px">
                        <i class="bx bx-cog"></i>
                    </th>
                    <th style="width: 50px">
                        <div class="btn btn-success btn-sm" v-on:click="addLangkah"><i class="bx bx-plus"></i></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(val_langkah, key_langkah) in langkah" :key="key_langkah">
                    <td>
                        <input type="text" class="form-control" v-model="val_langkah.judul_langkah" />
                    </td>
                    <td>
                        <div class="row" v-for="(val_hal, key_hal) in val_langkah.hal" :key="key_hal">
                            <div class="col">
                                <input type="text" class="form-control" v-model="val_hal.isi_hal" />
                            </div>
                            <div class="col-1" v-if="key_hal == 0">
                                <div class="btn btn-success btn-sm" v-on:click="addHall(key_langkah)"><i class="bx bx-plus"></i></div>
                            </div>
                            <div class="col-1" v-else>
                                <div class="btn btn-danger btn-sm" v-on:click="removeHal(key_langkah, key_hal)"><i class="bx bx-minus"></i></div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="btn btn-danger btn-sm" v-on:click="removeLangkah(key_langkah)"><i class="bx bx-minus"></i></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="text-end">
            <div style="margin-top: 100px">.........., .............</div>
        </div>
    </div>
    <div class="row mt-5">
        <table style="margin-top: 100px">
            <tr>
                <td style="width: 50%" class="text-center">___________________</td>
                <td style="width: 50%" class="text-center">___________________</td>
            </tr>
            <tr>
                <td style="width: 50%" class="text-center">Kandidat/Pemohon</td>
                <td style="width: 50%" class="text-center">Asesor</td>
            </tr>
        </table>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
