<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            jawaban: [],
            alasan: null,
            keterangan: [
                {
                    isi_keterangan: null,
                },
            ],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_jawaban = response_data_fix.konfig_template.find((konfig) => konfig.remark === "jawaban");
                        self.jawaban = JSON.parse(meta_jawaban.meta_template);
                        var meta_alasan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "alasan");
                        self.alasan = JSON.parse(meta_alasan.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    meta: JSON.stringify({
                        jawaban: self.jawaban,
                        alasan: self.alasan,
                        // keterangan: self.keterangan,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addJawaban() {
            this.jawaban.push({ jawaban: null });
        },
        removeJawaban(index) {
            this.jawaban.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.AK.04. BANDING ASESMEN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td>Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Tanggal Asesmen</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th>Jawablah dengan Ya atau Tidak pertanyaan-pertanyaan berikut ini :</th>
                        <th class="text-center">Ya</th>
                        <th class="text-center">Tidak</th>

                        <th style="width: 50px" class="text-center">
                            <div class="btn btn-success btn-sm" v-on:click="addJawaban"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in jawaban" :key="key">
                        <td>
                            <input type="text" class="form-control" v-model="val.isijawaban" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removeJawaban(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tr>
                    <td>Banding ini diajukan atas alasan sebagai berikut :</td>
                </tr>
                <tr>
                    <td style="height: 150px"></td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tr>
                    <td>Anda mempunyai hak mengajukan banding jika Anda menilai proses asesmen tidak sesuai SOP dan tidak memenuhi Prinsip Asesmen.</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tr style="height: 100px">
                    <td>Nama Peninjau</td>
                    <td>Tanggal Tanda Tangan Peninjau</td>
                    <td>Komentar</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
