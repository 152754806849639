<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            panduan: [],
            dokumen_portofolio: [],
            materi_wawancara: [
                {
                    isi_kode_unit: null,
                    isi_judul_unit: null,
                    elemen: [
                        {
                            isi_elemen: null,
                            substansi: [
                                {
                                    isi_substansi: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    console.log(response_data_fix);
                    if (response_data.meta.code == 200) {
                        var meta_panduan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "panduan");
                        var meta_dokumen_portofolio = response_data_fix.konfig_template.find((konfig) => konfig.remark === "dokumen_portofolio");
                        var meta_materi_wawancara = response_data_fix.konfig_template.find((konfig) => konfig.remark === "materi_wawancara");
                        self.panduan = JSON.parse(meta_panduan.meta_template);
                        self.dokumen_portofolio = JSON.parse(meta_dokumen_portofolio.meta_template);
                        self.materi_wawancara = JSON.parse(meta_materi_wawancara.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        panduan: self.panduan,
                        dokumen_portofolio: self.dokumen_portofolio,
                        materi_wawancara: self.materi_wawancara,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPanduan() {
            this.panduan.push({ isi_panduan: null });
        },
        removePanduan(index) {
            this.panduan.splice(index, 1);
        },
        addDokumen() {
            this.dokumen_portofolio.push({ isi_dokumen_portofolio: null });
        },
        removeDokumen(index) {
            this.dokumen_portofolio.splice(index, 1);
        },
        addMateri() {
            this.materi_wawancara.push({
                isi_kode_unit: null,
                isi_judul_unit: null,
                elemen: [
                    {
                        isi_elemen: null,
                        substansi: [
                            {
                                isi_substansi: null,
                            },
                        ],
                    },
                ],
            });
        },
        removeMateri(index) {
            this.materi_wawancara.splice(index, 1);
        },
        addElemen(indexMateri) {
            this.materi_wawancara[indexMateri].elemen.push({ isi_elemen: null, substansi: [{ isi_substansi: null }] });
        },
        removeElemen(indexMateri, indexElemen) {
            this.materi_wawancara[indexMateri].elemen.splice(indexElemen, 1);
        },
        addSubstansi(indexMateri, indexElemen) {
            this.materi_wawancara[indexMateri].elemen[indexElemen].substansi.push({ isi_substansi: null });
        },
        removeSubstansi(indexMateri, indexElemen, indexSubstansi) {
            this.materi_wawancara[indexMateri].elemen[indexElemen].substansi.splice(indexSubstansi, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.IA.08. CEKLIS VERIFIKASI PORTOFOLIO</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            PANDUAN BAGI ASESOR
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addPanduan()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in panduan" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="val.isi_panduan" />
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removePanduan(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th rowspan="3" class="text-center">Dokumen Portofolio:</th>
                        <th class="text-center" colspan="8">Aturan Bukti</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addDokumen()"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-center" colspan="2">Valid</th>
                        <th class="text-center" colspan="2">Asli</th>
                        <th class="text-center" colspan="2">Terkini</th>
                        <th class="text-center" colspan="2">Memadai</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th class="text-center">Ya</th>
                        <th class="text-center">Tidak</th>
                        <th class="text-center">Ya</th>
                        <th class="text-center">Tidak</th>
                        <th class="text-center">Ya</th>
                        <th class="text-center">Tidak</th>
                        <th class="text-center">Ya</th>
                        <th class="text-center">Tidak</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in dokumen_portofolio" :key="key">
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_dokumen_portofolio" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeDokumen(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            Materi Wawancara
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addMateri()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in materi_wawancara" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" placeholder="Kode Unit" class="form-control" v-model="val.isi_kode_unit" />
                                            <textarea v-model="val.isi_judul_unit" class="form-control" rows="3" cols="50" placeholder="Judul Unit"></textarea>
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removeMateri(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="row">
                                                <div class="col">
                                                    <table class="table table-responsive table-sm table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    Elemen
                                                                    <div class="float-end">
                                                                        <div class="btn btn-success btn-sm" v-on:click="addElemen(key)"><i class="bx bx-plus"></i></div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <ul>
                                                                        <li v-for="(elemenVal, elemenKey) in val.elemen" :key="elemenKey">
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <input type="text" class="form-control" v-model="elemenVal.isi_elemen" placeholder="Elemen" />
                                                                                </div>
                                                                                <div class="col-1">
                                                                                    <div class="btn btn-danger btn-sm" v-on:click="removeElemen(key, elemenKey)"><i class="bx bx-minus"></i></div>
                                                                                </div>
                                                                            </div>
                                                                            <ul>
                                                                                <li>
                                                                                    <div class="row">
                                                                                        <div class="col">
                                                                                            <table class="table table-responsive table-sm table-bordered">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <th>
                                                                                                            Materi/substansi wawancara (ELEMEN)
                                                                                                            <div class="float-end">
                                                                                                                <div class="btn btn-success btn-sm" v-on:click="addSubstansi(key, elemenKey)"><i class="bx bx-plus"></i></div>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <ul>
                                                                                                                <li v-for="(substansiVal, substansiKey) in elemenVal.substansi" :key="substansiKey">
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <input v-model="substansiVal.isi_substansi" class="form-control" placeholder="Materi/substansi wawancara" />
                                                                                                                        </div>
                                                                                                                        <div class="col-1">
                                                                                                                            <div class="btn btn-danger btn-sm" v-on:click="removeSubstansi(key, elemenKey, substansiKey)"><i class="bx bx-minus"></i></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
