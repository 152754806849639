<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            persiapan_validasi: [],
            dokumen_dan_acuan: [],
            keterampilan_komunikasi: [],
            aspek_validasi: [],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    console.log(response_data_fix);
                    if (response_data.meta.code == 200) {
                        var meta_persiapan_validasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "persiapan_validasi");
                        var meta_dokumen_dan_acuan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "dokumen_dan_acuan");
                        var meta_keterampilan_komunikasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "keterampilan_komunikasi");
                        var meta_aspek_validasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "aspek_validasi");
                        self.persiapan_validasi = JSON.parse(meta_persiapan_validasi.meta_template);
                        self.dokumen_dan_acuan = JSON.parse(meta_dokumen_dan_acuan.meta_template);
                        self.keterampilan_komunikasi = JSON.parse(meta_keterampilan_komunikasi.meta_template);
                        self.aspek_validasi = JSON.parse(meta_aspek_validasi.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template/",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        persiapan_validasi: self.persiapan_validasi,
                        dokumen_dan_acuan: self.dokumen_dan_acuan,
                        keterampilan_komunikasi: self.keterampilan_komunikasi,
                        aspek_validasi: self.aspek_validasi,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPersiapan() {
            this.persiapan_validasi.push({ isi_tujuan_validasi: null, isi_konteks_validasi: null, isi_pendekatan_validasi: null, });
        },
        removePersiapan(index) {
            this.persiapan_validasi.splice(index, 1);
        },
        addAcuan() {
            this.dokumen_dan_acuan.push({ isi_acuan_pembanding: null, isi_dokumen_terkait: null, });
        },
        removeAcuan(index) {
            this.dokumen_dan_acuan.splice(index, 1);
        },
        addKeterampilan() {
            this.keterampilan_komunikasi.push({ isi_keterampilan: null, });
        },
        removeKeterampilan(index) {
            this.keterampilan_komunikasi.splice(index, 1);
        },
        addAspek() {
            this.aspek_validasi.push({ isi_aspek_validasi: null, });
        },
        removeAspek(index) {
            this.aspek_validasi.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.VA. MEMBERIKAN KONTRIBUSI DALAM VALIDASI ASESMEN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">Tim Validasi</td>
                        <td>1. .................</td>
                        <td>Hari/Tanggal</td>
                        <td>: ....................</td>
                    </tr>
                    <tr>
                        <td>2. .................</td>
                        <td>Tempat</td>
                        <td>: ....................</td>
                    </tr>
                    <tr>
                        <td>Periode</td>
                        <td colspan="3">: 
                            <input type="radio" name="periode"> Sebelum Asesmen
                            <input type="radio" name="periode"> Pada saat Asesmen
                            <input type="radio" name="periode"> Setelah Asesmen
                        </td>
                    </tr>
                    <tr>
                        <td>Nama Skema</td>
                        <td colspan="3">: <b>PELAKSANA PATOLOGI DAN TOKSIKOLOGI</b></td>
                    </tr>
                    <tr>
                        <td>Nomor Skema</td>
                        <td colspan="3">: BIO-108/LSPHBF/V/2023</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                     <tr>
                        <th style="width:15px;">1. </th>
                        <th colspan="3">Menyiapkan Proses Validasi</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>Tujuan dan fokus validasi</th>
                        <th>Konteks validasi</th>
                        <th>Pendekatan validasi</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addPersiapan()"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val, key) in persiapan_validasi" :key="key">
                        <td></td>
                        <td>
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox" name="tujuan[]" class="form-check-input">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" v-model="val.isi_tujuan_validasi">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox" name="tujuan[]" class="form-check-input">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" v-model="val.isi_konteks_validasi">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox" name="tujuan[]" class="form-check-input">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" v-model="val.isi_pendekatan_validasi">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removePersiapan(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                     <tr>
                        <th style="width:15px;"></th>
                        <th>Acuan Pembanding :</th>
                        <th>Dokumen terkait dan bahan-bahan :</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addAcuan()"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val, key) in dokumen_dan_acuan" :key="key">
                        <td></td>
                        <td>
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox" name="tujuan[]" class="form-check-input">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" v-model="val.isi_acuan_pembanding">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox" name="tujuan[]" class="form-check-input">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" v-model="val.isi_dokumen_terkait">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeAcuan(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            Keterampilan komunikasi yang digunakan dalam kegiatan validasi :
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addKeterampilan()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in keterampilan_komunikasi" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="val.isi_keterampilan" />
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removeKeterampilan(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th rowspan="3" class="text-center">No.</th>
                        <th rowspan="3" class="text-center">Aspek-Aspek Dalam Kegiatan Validasi (Meninjau, Membandingkan, Mengevaluasi)</th>
                        <th colspan="8" class="text-center">Pemenuhan Terhadap :</th>
                        <th rowspan="3" class="text-center" style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addAspek()"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="4" class="text-center">Aturan Bukti</th>
                        <th colspan="4" class="text-center">Prinsip Asesmen</th>
                    </tr>
                    <tr>
                        <th class="text-center">V</th>
                        <th class="text-center">A</th>
                        <th class="text-center">T</th>
                        <th class="text-center">M</th>
                        <th class="text-center">V</th>
                        <th class="text-center">R</th>
                        <th class="text-center">F</th>
                        <th class="text-center">F</th>
                    </tr>
                    <tr v-for="(val, key) in aspek_validasi" :key="key">
                        <td>{{ key + 1 }}. </td>
                        <td><input type="text" class="form-control" v-model="val.isi_aspek_validasi"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><input type="checkbox"></td>
                        <td><div class="btn btn-danger btn-sm" v-on:click="removeAspek(key)"><i class="bx bx-minus"></i></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
