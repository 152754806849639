<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            panduan: [],
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            konfig_skema: [],
        };
    },
    mounted() {
        this.getDataKonfigSkema();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfigSkema() {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-konfig-skema",
                params: {
                    skema_id: self.skema_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.konfig_skema = response_data_fix.referensi[0];
                        console.log(response_data_fix.referensi[0]);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id + "&skema_id=" + self.skema_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        panduan: self.panduan,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPanduan() {
            this.panduan.push({ isi_panduan: null });
        },
        removePanduan(index) {
            this.panduan.splice(index, 1);
        },
    },
};
</script>
<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.IA.02. TUGAS PRAKTIK DEMONSTRASI</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tr>
                    <td rowspan="2">
                        Skema Sertifikasi <br />
                        (KKNI/Okupasi/Klaster)
                    </td>
                    <td>Judul</td>
                    <td>: PELAKSANA PATOLOGI DAN TOKSIKOLOGI</td>
                </tr>
                <tr>
                    <td>Nomor</td>
                    <td>: BIO-108/LSPHBF/V/2023</td>
                </tr>
                <tr>
                    <td colspan="2">TUK</td>
                    <td>: Sewaktu/Tempat Kerja/Mandiri*</td>
                </tr>
                <tr>
                    <td colspan="2">Nama Asesor</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td colspan="2">Nama Asesi</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td colspan="2">Tanggal</td>
                    <td>:</td>
                </tr>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div v-for="(val_unit, key_unit) in konfig_skema.konfig_unit_kompetensi" :key="key_unit" style="margin-bottom: 15px">
                <table class="table table-responsive table-sm table-bordered" style="margin-bottom: 15px">
                    <thead>
                        <tr>
                            <td rowspan="2" style="width: 250px">Unit Kompetensi {{ key_unit + 1 }}:</td>
                            <td>Kode Unit</td>
                            <td>
                                {{ val_unit.unit_kom_kode }}
                            </td>
                        </tr>
                        <tr>
                            <td>Nama Sekema</td>
                            <td>
                                {{ val_unit.unit_kom_nama }}
                            </td>
                        </tr>
                    </thead>
                </table>
                <ol type="A">
                    <li>
                        <b>Petunjuk</b>

                        <ol>
                            <li>Baca dan pelajari setiap instruksi kerja di bawah ini dengan cermat sebelum melaksanakan praktek</li>
                            <li>Klarifikasi kepada Asesor apabila ada hal-hal yang belum jelas</li>
                            <li>Laksanakan pekerjaan sesuai dengan urutan proses yang sudah ditetapkan</li>
                            <li>Seluruh proses kerja mengacu kepada SOP/WI yang dipersyaratkan</li>
                        </ol>
                    </li>
                    <li>
                        <b>Skenario (Pemenuhan Terhadap Dimensi TS, TMS, dan JRES)</b>
                        <p>
                            Dalam rangka pemenuhan terhadap kompetensi sesuai persyaratan unit kompetensi pada skema sertifikasi Pelaksana Patologi dan Toksikologi, saudara diminta untuk mendemonstrasikan unit kompetensi “Menerapkan Keselamatan Dan Kesehatan Kerja (K3) Dan Lingkungan Di Areal Biopharmaceutical”. <br />
                            Peralatan yang dibutuhkan disiapkan oleh LSP di TUK berupa ATK.<br />
                            Durasi waktu yang disiapkan untuk saudara mendemonstrasikan unit kompetensi ini sampai dengan dilaporkan pada atasan dalam rentang waktu (maksimal 1 hari kerja)<br />
                            Laksanakan sesuai langkah kerja dibawah ini,<br />
                        </p>
                    </li>
                    <li>
                        <b>Langkah kerja</b>
                        <ol>
                            <li>Melakukan persiapan lokasi dan kondisi pengujian</li>
                            <li>Pelakasanaan pengujian</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
