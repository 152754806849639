<script>
import FR_IA_11 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_11";
import CEKLIST_KONSULTASI_PRA_ASSESMENT from "@/router/views/konfigurasi/dokumen-muk/generator/CEKLIST_KONSULTASI_PRA_ASSESMENT";
import FR_MAPA_02 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_MAPA_02";
import FR_APL_01 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_APL_01";
import FR_AK_04 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_04";
import FR_AK_03 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_03";
import FR_AK_03_Tambahan from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_03_Tambahan";
import FR_IA_10_BUKTI_PIHAK_KETIGA from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_10_BUKTI_PIHAK_KETIGA";
import DAFTAR_HADIR_ASESMEN from "@/router/views/konfigurasi/dokumen-muk/generator/DAFTAR_HADIR_ASESMEN";
import FR_AK_06 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_06";
import FR_AK_02_REKAMAN_ASESMEN from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_02_REKAMAN_ASESMEN";
import FR_AK_01_PERSETUJUAN_KERAHASIAAN_PRA from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_01_PERSETUJUAN_KERAHASIAAN_PRA";
import FR_IA_08 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_08";
import FR_IA_09 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_09";
import DAFTAR_PERIKSA_OBSERVASI from "@/router/views/konfigurasi/dokumen-muk/generator/DAFTAR_PERIKSA_OBSERVASI";
import DAFTAR_PERIKSA_PORTOFOLIO from "@/router/views/konfigurasi/dokumen-muk/generator/DAFTAR_PERIKSA_PORTOFOLIO";
import FR_VA_MKVA_pasca_asesmen from "@/router/views/konfigurasi/dokumen-muk/generator/FR_VA_MKVA_pasca_asesmen";
import FR_VA_MKVA_pra_asesmen from "@/router/views/konfigurasi/dokumen-muk/generator/FR_VA_MKVA_pra_asesmen";
import FR_APL_02 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_APL_02";
import FR_IA_01 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_01";
import FR_IA_02 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_02";
import FR_IA_03 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_03";
import FR_IA_07 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_07";
import CEKLIST_VERIFIKASI_TUK from "@/router/views/konfigurasi/dokumen-muk/generator/CEKLIST_VERIFIKASI_TUK";
import FR_VA_MKVA_sesaat_asesmen from "@/router/views/konfigurasi/dokumen-muk/generator/FR_VA_MKVA_sesaat_asesmen";
import FR_AK_05 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_AK_05";
import FR_IA_05 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_05";
import FR_IA_06 from "@/router/views/konfigurasi/dokumen-muk/generator/FR_IA_06";
import FR_MAPA_01_OBSERVASI from "@/router/views/konfigurasi/dokumen-muk/generator/FR_MAPA_01_OBSERVASI";
import FR_MAPA_01_PORTOFOLIO from "@/router/views/konfigurasi/dokumen-muk/generator/FR_MAPA_01_PORTOFOLIO";
import Skema_Sertifikasi from "@/router/views/konfigurasi/dokumen-muk/generator/Skema_Sertifikasi";
import SKKK_lama from "@/router/views/konfigurasi/dokumen-muk/generator/SKKK_lama";
import SKKK_baru from "@/router/views/konfigurasi/dokumen-muk/generator/SKKK_baru";

export default {
    props: ["vue_form_generator", "dokumen_id", "penyusunan_muk_id"],
    components: {
        FR_IA_11,
        CEKLIST_KONSULTASI_PRA_ASSESMENT,
        FR_MAPA_02,
        FR_APL_01,
        FR_AK_03,
        FR_AK_03_Tambahan,
        FR_AK_04,
        FR_IA_10_BUKTI_PIHAK_KETIGA,
        DAFTAR_HADIR_ASESMEN,
        FR_AK_06,
        FR_AK_02_REKAMAN_ASESMEN,
        FR_AK_01_PERSETUJUAN_KERAHASIAAN_PRA,
        FR_IA_08,
        FR_IA_09,
        DAFTAR_PERIKSA_OBSERVASI,
        DAFTAR_PERIKSA_PORTOFOLIO,
        FR_VA_MKVA_pasca_asesmen,
        FR_VA_MKVA_pra_asesmen,
        FR_APL_02,
        FR_IA_01,
        FR_IA_02,
        FR_IA_03,
        FR_IA_07,
        CEKLIST_VERIFIKASI_TUK,
        FR_VA_MKVA_sesaat_asesmen,
        FR_AK_05,
        FR_IA_05,
        FR_IA_06,
        FR_MAPA_01_OBSERVASI,
        FR_MAPA_01_PORTOFOLIO,
        Skema_Sertifikasi,
        SKKK_lama,
        SKKK_baru,
    },
};
</script>

<template>
    <div class="col-md-12">
        <FR_IA_11 v-if="vue_form_generator == 'FR_IA_11'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_11>
        <CEKLIST_KONSULTASI_PRA_ASSESMENT v-else-if="vue_form_generator == 'CEKLIST_KONSULTASI_PRA_ASSESMENT'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></CEKLIST_KONSULTASI_PRA_ASSESMENT>
        <FR_MAPA_02 v-else-if="vue_form_generator == 'FR_MAPA_02'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_MAPA_02>
        <FR_APL_01 v-else-if="vue_form_generator == 'FR_APL_01'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_APL_01>
        <FR_AK_03 v-else-if="vue_form_generator == 'FR_AK_03'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_03>
        <FR_AK_03_Tambahan v-else-if="vue_form_generator == 'FR_AK_03_Tambahan'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_03_Tambahan>
        <FR_AK_04 v-else-if="vue_form_generator == 'FR_AK_04'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_04>
        <FR_IA_10_BUKTI_PIHAK_KETIGA v-else-if="vue_form_generator == 'FR_IA_10_BUKTI_PIHAK_KETIGA'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_10_BUKTI_PIHAK_KETIGA>
        <DAFTAR_HADIR_ASESMEN v-else-if="vue_form_generator == 'DAFTAR_HADIR_ASESMEN'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></DAFTAR_HADIR_ASESMEN>
        <FR_AK_06 v-else-if="vue_form_generator == 'FR_AK_06'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_06>
        <FR_AK_02_REKAMAN_ASESMEN v-else-if="vue_form_generator == 'FR_AK_02_REKAMAN_ASESMEN'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_02_REKAMAN_ASESMEN>
        <FR_AK_01_PERSETUJUAN_KERAHASIAAN_PRA v-else-if="vue_form_generator == 'FR_AK_01_PERSETUJUAN_KERAHASIAAN_PRA'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_01_PERSETUJUAN_KERAHASIAAN_PRA>
        <FR_IA_08 v-else-if="vue_form_generator == 'FR_IA_08'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_08>
        <FR_IA_09 v-else-if="vue_form_generator == 'FR_IA_09'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_09>
        <DAFTAR_PERIKSA_PORTOFOLIO v-else-if="vue_form_generator == 'DAFTAR_PERIKSA_PORTOFOLIO'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></DAFTAR_PERIKSA_PORTOFOLIO>
        <DAFTAR_PERIKSA_OBSERVASI v-else-if="vue_form_generator == 'DAFTAR_PERIKSA_OBSERVASI'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></DAFTAR_PERIKSA_OBSERVASI>
        <FR_VA_MKVA_pasca_asesmen v-else-if="vue_form_generator == 'FR_VA_MKVA_pasca_asesmen'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_VA_MKVA_pasca_asesmen>
        <FR_VA_MKVA_pra_asesmen v-else-if="vue_form_generator == 'FR_VA_MKVA_pra_asesmen'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_VA_MKVA_pra_asesmen>
        <FR_APL_02 v-else-if="vue_form_generator == 'FR_APL_02'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_APL_02>
        <FR_IA_01 v-else-if="vue_form_generator == 'FR_IA_01'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_01>
        <FR_IA_02 v-else-if="vue_form_generator == 'FR_IA_02'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_02>
        <FR_IA_03 v-else-if="vue_form_generator == 'FR_IA_03'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_03>
        <FR_IA_07 v-else-if="vue_form_generator == 'FR_IA_07'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_07>
        <CEKLIST_VERIFIKASI_TUK v-else-if="vue_form_generator == 'CEKLIST_VERIFIKASI_TUK'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></CEKLIST_VERIFIKASI_TUK>
        <FR_VA_MKVA_sesaat_asesmen v-else-if="vue_form_generator == 'FR_VA_MKVA_sesaat_asesmen'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_VA_MKVA_sesaat_asesmen>
        <FR_AK_05 v-else-if="vue_form_generator == 'FR_AK_05'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_AK_05>
        <FR_IA_05 v-else-if="vue_form_generator == 'FR_IA_05'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_05>
        <FR_IA_06 v-else-if="vue_form_generator == 'FR_IA_06'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_IA_06>
        <FR_MAPA_01_OBSERVASI v-else-if="vue_form_generator == 'FR_MAPA_01_OBSERVASI'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_MAPA_01_OBSERVASI>
        <FR_MAPA_01_PORTOFOLIO v-else-if="vue_form_generator == 'FR_MAPA_01_PORTOFOLIO'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></FR_MAPA_01_PORTOFOLIO>
        <Skema_Sertifikasi v-else-if="vue_form_generator == 'Skema_Sertifikasi'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></Skema_Sertifikasi>
        <SKKK_lama v-else-if="vue_form_generator == 'SKKK_lama'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></SKKK_lama>
        <SKKK_baru v-else-if="vue_form_generator == 'SKKK_baru'" :dokumen_id="dokumen_id" :penyusunan_muk_id="penyusunan_muk_id"></SKKK_baru>

    </div>
</template>
