<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            konfig_skema: [],
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            petunjuk : [],
            ruang : [],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var petunjuk = response_data_fix.konfig_template.find((konfig) => konfig.remark === "petunjuk");
                        self.petunjuk = JSON.parse(petunjuk.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        petunjuk : self.petunjuk,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addLangkah() {
            this.langkah.push({
                judul_langkah: null,
                hal: [{ isi_hal: null }],
            });
        },
        removeLangkah(index) {
            this.langkah.splice(index, 1);
        },
        addHall(index_langkah) {
            this.langkah[index_langkah].hal.push({
                isi_hal: null,
            });
        },
        removeHal(index_langkah, index_hal) {
            this.langkah[index_langkah].hal.splice(index_hal, 1);
        },
        addRuang() {
            this.ruang.push({
                nama_ruang: null,
                kelengkapan: [{ nama_kelengkapan: null,speksifikasi:null,jumlah:null,kondisi:"baik"}],
            });
        },
        removeRuang(index) {
            this.ruang.splice(index, 1);
        },
        addPetunjuk() {
            this.petunjuk.push({
                nama_petunjuk: null,
            });
            console.log(this.petunjuk);
        },
        removePetunjuk(index) {
            this.petunjuk.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center">Verifikasi TUK LSP HOLDING BUMN FARMASI</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="table table-responsive table-sm">
            <thead>
                <tr>
                    <th colspan="2">Nama dan Kode Skema</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Okupasi Supporting Staff Produksi</td>
                    <td>(BIO/LSPHBF/VI/2021)</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm">
            <tbody>
                <tr>
                    <th>Nama TUK</th>
                    <td style="width: 20px">:</td>
                    <td>Ruang Produksi FPVP</td>
                </tr>
                <tr>
                    <th>Jenis TUK</th>
                    <td style="width: 20px">:</td>
                    <td>Sewaktu</td>
                </tr>
                <tr>
                    <th>Tanggal Verifikasi</th>
                    <td style="width: 20px">:</td>
                    <td>..............</td>
                </tr>
                <tr>
                    <th>Verifikator</th>
                    <td style="width: 20px">:</td>
                    <td>..............</td>
                </tr>
            </tbody>
        </table>
        <hr />
    </div>
    <div class="row">
        <div class="col-md-12">
            <h4>I. Petunjuk</h4>
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" style="width: 50px"> No</th>
                        <th class="text-center">Nama Petunjuk</th>
                        <th class="text-center" style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addPetunjuk"><i class="bx bx-plus"></i></div>
                        </th>


                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, index) in petunjuk" :key="index">
                        <td class="text-center">{{ index+1 }}</td>
                        <td class="text-center">
                            <input type="text" class="form-control" v-model="val.nama_petunjuk" />
                        </td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removePetunjuk(index)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="col-md-12">
            <h4>II. Standar Persyaratan Peralatan Ruang Meeting Produksi FPVP</h4>
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th rowspan="2">No</th>
                        <th rowspan="2">Nama Alat</th>
                        <th rowspan="2">Spesifikasi</th>
                        <th rowspan="2">Jumlah</th>
                        <th rowspan="2">Kondisi</th>
                        <th colspan="2">Tingkat Kesesuaian</th>
                    </tr>
                    <tr>
                        <th>Baik</th>
                        <th>Belum Baik</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Meja</td>
                        <td>Dilengkapi dengan stopkontak dan konektor HDMI</td>
                        <td>1</td>
                        <td>Baik</td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col">
                <hr class="mt-3" />
                <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
            </div>
        </div>
    </div>
</template>
