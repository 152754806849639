<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            pertanyaan_wawancara: [
                {
                    isi_kode_unit: null,
                    isi_judul_unit: null,
                    bukti_kompetensi: [
                        {
                            isi_bukti_kompetensi: null,
                        },
                    ],
                    daftar_pertanyaan: [
                        {
                            isi_ketentuan: null,
                            isi_pertanyaan: null,
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_pertanyaan_wawancara = response_data_fix.konfig_template.find((konfig) => konfig.remark === "pertanyaan_wawancara");
                        self.pertanyaan_wawancara = JSON.parse(meta_pertanyaan_wawancara.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        pertanyaan_wawancara: self.pertanyaan_wawancara,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPertanyaan() {
            this.pertanyaan_wawancara.push({
                isi_kode_unit: null,
                isi_judul_unit: null,
                bukti_kompetensi: [
                    {
                        isi_bukti_kompetensi: null,
                    },
                ],
                daftar_pertanyaan: [
                    {
                        isi_ketentuan: null,
                        isi_pertanyaan: null,
                    },
                ],
            });
        },
        removePertanyaan(index) {
            this.pertanyaan_wawancara.splice(index, 1);
        },
        addBuktiKompetensi(indexPertanyaan) {
            this.pertanyaan_wawancara[indexPertanyaan].bukti_kompetensi.push({ isi_bukti_kompetensi: null });
        },
        removeBuktiKompetensi(indexPertanyaan, indexBukti) {
            this.pertanyaan_wawancara[indexPertanyaan].bukti_kompetensi.splice(indexBukti, 1);
        },
        addDaftarPertanyaan(indexPertanyaan) {
            this.pertanyaan_wawancara[indexPertanyaan].daftar_pertanyaan.push({ isi_ketentuan: null, isi_pertanyaan: null });
        },
        removeDaftarPertanyaan(indexPertanyaan, indexDaftarPertanyaan) {
            this.pertanyaan_wawancara[indexPertanyaan].daftar_pertanyaan.splice(indexDaftarPertanyaan, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.IA.09. PERTANYAAN WAWANCARA</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            Pertanyaan Wawancara
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addPertanyaan()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in pertanyaan_wawancara" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" placeholder="Kode Unit" class="form-control" v-model="val.isi_kode_unit" />
                                            <textarea v-model="val.isi_judul_unit" class="form-control" rows="3" cols="50" placeholder="Judul Unit"></textarea>
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removeUnitKompetensi(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="row">
                                                <div class="col">
                                                    <table class="table table-responsive table-sm table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    Bukti Kompetensi
                                                                    <div class="float-end">
                                                                        <div class="btn btn-success btn-sm" v-on:click="addBuktiKompetensi(key)"><i class="bx bx-plus"></i></div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <ul>
                                                                        <li v-for="(buktiVal, buktiKey) in val.bukti_kompetensi" :key="buktiKey">
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <input type="text" class="form-control" v-model="buktiVal.isi_bukti_kompetensi" />
                                                                                </div>
                                                                                <div class="col-1">
                                                                                    <div class="btn btn-danger btn-sm" v-on:click="removeBuktiKompetensi(key, buktiKey)"><i class="bx bx-minus"></i></div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row">
                                                <div class="col">
                                                    <table class="table table-responsive table-sm table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    Daftar Pertanyaan
                                                                    <div class="float-end">
                                                                        <div class="btn btn-success btn-sm" v-on:click="addDaftarPertanyaan(key)"><i class="bx bx-plus"></i></div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <ul>
                                                                        <li v-for="(daftartVal, daftarKey) in val.daftar_pertanyaan" :key="daftarKey">
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <textarea v-model="daftartVal.isi_ketentuan" class="form-control" rows="3" cols="50" placeholder="Ketentuan Pertanyaan"></textarea>
                                                                                    <textarea v-model="daftartVal.isi_pertanyaan" class="form-control" rows="3" cols="50" placeholder="Pertanyaan"></textarea>
                                                                                </div>
                                                                                <div class="col-1">
                                                                                    <div class="btn btn-danger btn-sm" v-on:click="removeDaftarPertanyaan(key, daftarKey)"><i class="bx bx-minus"></i></div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
