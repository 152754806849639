<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id"],
    data() {
        return {
            konfig_skema: [],
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            jenis_dokumen: "SKKK_BARU",
            preview_skkk: null,
        };
    },
    mounted() {
        this.getPreviewSKK();
    },
    methods: {
        // Proses get konfig template dokumen muk
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        langkah: self.langkah,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // self.$router.push({ name: "konfigurasi-dokumen-muk" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addLangkah() {
            this.langkah.push({
                judul_langkah: null,
                hal: [{ isi_hal: null }],
            });
        },
        removeLangkah(index) {
            this.langkah.splice(index, 1);
        },
        getPreviewSKK(){
        let self = this;
        
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/show-skkk", {
                    params: {
                        jenis_dokumen : self.jenis_dokumen,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.preview_skkk = res_fix.data.skk_url;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center">Standar Kompetensi Kerja Khusus</h3>
        </div>
        <!-- <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div> -->
        <div class="col-12 mt-4" v-if="preview_skkk">
            <iframe v-bind:src="preview_skkk" width="100%" height="400"></iframe>
        </div>
    </div>
</template>
