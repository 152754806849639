<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            umpanbalik: [],
            catatanLain: null,
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var umpanbalik = response_data_fix.konfig_template.find((konfig) => konfig.remark === "umpanbalik");
                        self.umpanbalik = JSON.parse(umpanbalik.meta_template);
                        var catatanLain = response_data_fix.konfig_template.find((konfig) => konfig.remark === "catatanLain");
                        self.catatanLain = JSON.parse(catatanLain.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        umpanbalik: self.umpanbalik,
                        catatanLain: self.catatanLain,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        // addLangkah() {
        //     this.langkah.push({
        //         judul_langkah: null,
        //         hal: [{ isi_hal: null }],
        //     });
        // },
        // removeLangkah(index) {
        //     this.langkah.splice(index, 1);
        // },
        // addHall(index_langkah) {
        //     this.langkah[index_langkah].hal.push({
        //         isi_hal: null,
        //     });
        // },
        // removeHal(index_langkah, index_hal) {
        //     this.langkah[index_langkah].hal.splice(index_hal, 1);
        // },
        addUmpanBalik() {
            this.umpanbalik.push({
                komponen: null,
            });
        },
        removeUmpanBalik(index) {
            this.umpanbalik.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center">FR.AK.03 UMPAN BALIK DAN CATATAN ASESMEN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tbody>
                <tr>
                    <td>Nama asesi</td>
                    <td>:</td>
                    <td>Hari/ Tanggal</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td>Nama Asesor</td>
                    <td>:</td>
                    <td>Waktu</td>
                    <td>:</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <p class="mt-2">Umpan balik dari Asesi (diisi oleh Asesi setelah pengambilan keputusan) :</p>
        <table class="table table-responsive table-sm table-bordered">
            <thead class="text-center">
                <tr style="background-color: rgb(230, 230, 230)">
                    <th rowspan="2">KOMPONEN</th>
                    <th colspan="2">Hasil</th>
                    <th rowspan="2">Catatan/Komentar Asesi</th>
                    <th rowspan="2" style="width: 10%">
                        <div class="btn btn-success btn-sm" v-on:click="addUmpanBalik"><i class="bx bx-plus"></i></div>
                    </th>
                </tr>
                <tr style="background-color: rgb(230, 230, 230)">
                    <th>Ya</th>
                    <th>Tidak</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(val_umpanbalik, key_umpanbalik) in umpanbalik" :key="key_umpanbalik">
                    <td>
                        <input type="text" class="form-control" v-model="val_umpanbalik.isi_umpanbalik" />
                    </td>
                    <td class="text-center">
                        <input type="checkbox" />
                    </td>
                    <td class="text-center">
                        <input type="checkbox" />
                    </td>
                    <td>
                        <textarea class="form-control" v-model="val_umpanbalik.isi_catatan"></textarea>
                    </td>
                    <td class="text-center">
                        <div class="btn btn-danger btn-sm" v-on:click="removeUmpanBalik(key_umpanbalik)"><i class="bx bx-minus"></i></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col-md" style="height: 150px">
            <p class="mt-2">Catatan/komentar lainnya (apabila ada) :</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
