<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            panduan_pihak_ketiga: [],
            pertanyaan: [
                {
                    isi_pertanyaan: null,
                },
            ],
            uraian: [],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_panduan_pihak_ketiga = response_data_fix.konfig_template.find((konfig) => konfig.remark === "panduan_pihak_ketiga");
                        var meta_pertanyaan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "pertanyaan");
                        var meta_uraian = response_data_fix.konfig_template.find((konfig) => konfig.remark === "uraian");
                        self.panduan_pihak_ketiga = JSON.parse(meta_panduan_pihak_ketiga.meta_template);
                        self.pertanyaan = JSON.parse(meta_pertanyaan.meta_template);
                        self.uraian = JSON.parse(meta_uraian.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        panduan_pihak_ketiga: self.panduan_pihak_ketiga,
                        pertanyaan: self.pertanyaan,
                        uraian: self.uraian,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPanduanPihakKetiga() {
            this.panduan_pihak_ketiga.push({ isi_panduan_pihak_ketiga: null });
        },
        removePanduanPihakKetiga(index) {
            this.panduan_pihak_ketiga.splice(index, 1);
        },
        addKegiatanPihakKetiga() {
            this.pertanyaan.push({ isi_pertanyaan: null });
        },
        removeKegiatanPihakKetiga(index) {
            this.pertanyaan.splice(index, 1);
        },
        addUraian() {
            this.uraian.push({ isi_uraian: null });
        },
        removeUraian(index) {
            this.uraian.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.IA.10. KLARIFIKASI BUKTI PIHAK KETIGA</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">TUK</td>
                        <td>: Sewaktu/Tempat Kerja/Mandiri*</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Tanggal</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            PANDUAN BAGI PENINJAU/ASESOR
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addPanduanPihakKetiga()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in panduan_pihak_ketiga" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="val.isi_panduan_pihak_ketiga" />
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removePanduanPihakKetiga(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tr>
                    <td colspan="2">Nama Pengawas/penyelia/atasan/orang lain di perusahaan :</td>
                </tr>
                <tr>
                    <td style="width: 250px">Tempat Kerja</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td>Alamat</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td>Telepon</td>
                    <td>:</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th>Pertanyaan</th>
                        <th class="text-center" colspan="2">Ya / Tidak</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addKegiatanPihakKetiga()"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in pertanyaan" :key="key">
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_pertanyaan" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="checkbox" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeKegiatanPihakKetiga(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            PERTANYAAN URAIAN
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addUraian()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in uraian" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="val.isi_uraian" />
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removeUraian(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
