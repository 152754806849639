<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            konfig_skema: {
            konfig_unit_kompetensi: [
                    { skema_kode: "BIO.LS-00.044.01", unit_kom_nama: "Menerapkan Keselamatan dan Kesehatan Kerja (K3) dan Lingkungan di Areal Biopharmaceutical" },
                    { skema_kode: "BIO.QC-01.077.01", unit_kom_nama: "Melakukan Pengondisian Sarana dan Prasarana Pengujian" },
                    { skema_kode: "BIO.QC-01.096.01", unit_kom_nama: "Melakukan Persiapan Hewan Untuk Uji In Vivo" }
                ]
            },
            lampiran: [],
            unit_kompetensi: [],
        };
    },
    mounted() {
        this.getDataKonfigSkema();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfigSkema() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var unit_kompetensi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "unit_kompetensi");
                        self.unit_kompetensi = JSON.parse(unit_kompetensi.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id + "&skema_id=" + self.skema_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        lampiran: self.lampiran,
                        unit_kompetensi: self.unit_kompetensi,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        addLampiran() {
            this.lampiran.push({ isi_lampiran: null });
        },
        removeLampiran(index) {
            this.lampiran.splice(index, 1);
        },
        addUnit(){
            this.unit_kompetensi.push({ unit_kom_nama: null, unit_kom_ket: null });
        },
        removeUnit(index){
            this.unit_kompetensi.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.AK.02 REKAMAN ASESMEN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td>Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Skema sertifikasi (bila tersedia)</td>
                        <td>: PELAKSANA PATOLOGI DAN TOKSIKOLOGI</td>
                    </tr>
                    <tr>
                        <td>Unit Kompetensi</td>
                        <td>
                            <div v-for="(val_unit, key_unit) in konfig_skema.konfig_unit_kompetensi" :key="key_unit">
                                <p style="text-align: left"><strong>{{ val_unit.skema_kode }}</strong></p>
                                <p style="text-align: left">{{ val_unit.unit_kom_nama }}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Tanggal mulainya asesmen</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Tanggal selesainya asesmen</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">Beri tanda centang (√) di kolom yang sesuai untuk mencerminkan bukti yang diperoleh untuk menentukan Kompetensi asesi untuk setiap Unit Kompetensi.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" >Unit Kompetensi</th>
                        <th class="text-center" >Obs.-Dem</th>
                        <th class="text-center" >Portofolio</th>
                        <th class="text-center" >Pihak Ketiga</th>
                        <th class="text-center" >Pertanyaan wawancara</th>
                        <th class="text-center" >Pertanyaan lisan</th>
                        <th class="text-center" >Pertanyaan tertulis</th>
                        <th class="text-center" >Pertanyaan tertulis</th>
                        <th class="text-center" >Proyek kerja</th>
                        <th class="text-center" >Lainnya</th>

                        <th style="width: 50px" >
                            <div class="btn btn-success btn-sm" v-on:click="addUnit"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in unit_kompetensi" :key="key">
                        <td>
                            <input type="text" class="form-control" v-model="val.unit_kom_nama" placeholder="Unit Kompetensi" />
                            <input type="text" class="form-control ml-2" v-model="val.unit_kom_ket" placeholder="Keterangan" />

                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeUnit(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tr style="height: 100px">
                <td colspan="2">Rekomendasi hasil asesmen</td>
                <td colspan="2">
                    <h6>Kompeten/ Belum kompeten</h6>
                </td>
            </tr>
            <tr style="height: 100px">
                <td colspan="2">Tindak lanjut yang dibutuhkan <br> <span>(Masukkan pekerjaan tambahan dan asesmen yang diperlukan untuk mencapai kompetensi) </span></td>
                <td colspan="2"></td>
            </tr>
            <tr style="height: 100px">
                <td colspan="2">Komentar/ Observasi oleh asesor</td>
                <td colspan="2"></td>
            </tr>
            <tr style="height: 100px">
                <td class="table-tandatangan">Tanda Tangan Asesor</td>
                <td class="table-tandatangan" stlye="witdh: 100px"></td>
                <td class="table-tandatangan"> Tanggal:</td>
                <td class="table-tandatangan" stlye="witdh: 100px"></td>


            </tr>
            <tr style="height: 100px">
                <td class="table-tandatangan">Tanda Tangan Asesi</td>
                <td class="table-tandatangan"></td>
                <td class="table-tandatangan"> Tanggal:</td>
                <td class="table-tandatangan"></td>

            </tr>
        </table>
    </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>

<style scoped>
    .table-tandatangan{
        width: 25%;
    }
    span{
        font-size: 10px;
    }
</style>

