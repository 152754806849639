<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            penjelasan: [],
            aspekDitinjauPertama: [],
            aspekDitinjauKedua: [],
            catatanLainTambahan: null,
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_penjelasan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "penjelasan");
                        self.penjelasan = JSON.parse(meta_penjelasan.meta_template);
                        var meta_aspekDitinjauPertama = response_data_fix.konfig_template.find((konfig) => konfig.remark === "aspekDitinjauPertama");
                        self.aspekDitinjauPertama = JSON.parse(meta_aspekDitinjauPertama.meta_template);
                        var meta_aspekDitinjauKedua = response_data_fix.konfig_template.find((konfig) => konfig.remark === "aspekDitinjauKedua");
                        self.aspekDitinjauKedua = JSON.parse(meta_aspekDitinjauKedua.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        penjelasan: self.penjelasan,
                        aspekDitinjauPertama: self.aspekDitinjauPertama,
                        aspekDitinjauKedua: self.aspekDitinjauKedua,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPenjelasan() {
            this.penjelasan.push({
                isi_keterangan: null,
            });
        },
        removePenjelasan(index) {
            this.penjelasan.splice(index, 1);
        },
        addAspekPertama() {
            this.aspekDitinjauPertama.push({
                isi_aspek: null,
            });
        },
        removeAspekPertama(index) {
            this.aspekDitinjauPertama.splice(index, 1);
        },
        addAspekKedua() {
            this.aspekDitinjauKedua.push({
                isi_aspekKedua: null,
            });
        },
        removeAspekKedua(index) {
            this.aspekDitinjauKedua.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center">FR.AK.06 MENINJAU PROSES ASESMEN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td style="width: 550px">:</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>TUK</td>
                        <td>:</td>
                        <td>Sewaktu/Tempat Kerja/Mandiri</td>
                    </tr>
                    <tr>
                        <td collspan="2">Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td collspan="2">Tanggal</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="mt-2" style="font-weight: bold">Penjelasan:</p>
            <table class="table table-responsive table-sm table-bordered">
                <thead class="text-center">
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th stlye="width:5%">No</th>
                        <th>Keterangan</th>
                        <th>
                            <div class="text-center">
                                <div class="btn btn-success btn-sm" v-on:click="addPenjelasan()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val_keterangan, key_keterangan) in penjelasan" :key="key_keterangan">
                        <td class="text-center" stlye="width:5%">{{ key_keterangan + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="val_keterangan.isi_keterangan" />
                        </td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removePenjelasan(key_keterangan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <hr />
    <div class="row mt-4">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead class="text-center">
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th rowspan="2">Aspek yang ditinjau</th>
                        <th colspan="4">Kesesuaian dengan prinsip asesmen</th>
                        <th rowspan="2">
                            <div class="text-center">
                                <div class="btn btn-success btn-sm" v-on:click="addAspekPertama()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th class="text-center">Validitas</th>
                        <th class="text-center">Reliabel</th>
                        <th class="text-center">Fleksibel</th>
                        <th class="text-center">Adil</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val_aspek, key_aspek) in aspekDitinjauPertama" :key="key_aspek">
                        <td>
                            <input type="text" class="form-control" v-model="val_aspek.isi_aspek" />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removeAspekPertama(key_aspek)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12">
            <p class="mt-2">Rekomendasi untuk peningkatan:</p>
            <textarea class="form-control" v-model="catatanLainTambahan"></textarea>
        </div>
    </div>
    <hr />
    <div class="row mt-4">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead class="text-center">
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th rowspan="2">Aspek yang ditinjau</th>
                        <th colspan="5">Pemenuhan dimensi kompetensi</th>
                        <th rowspan="2">
                            <div class="text-center">
                                <div class="btn btn-success btn-sm" v-on:click="addAspekKedua()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th class="text-center">Task Skills</th>
                        <th class="text-center">Task Management Skills</th>
                        <th class="text-center">Contingency Management Skills</th>
                        <th class="text-center">Job Role/ Environment Skills</th>
                        <th class="text-center">Transfer Skills</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val_aspekKedua, key_aspekKedua) in aspekDitinjauKedua" :key="key_aspekKedua">
                        <td>
                            <textarea class="form-control" v-model="val_aspekKedua.isi_aspekKedua"></textarea>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removeAspekKedua(val_aspekKedua)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12">
            <p class="mt-2">Rekomendasi untuk peningkatan:</p>
            <textarea class="form-control" v-model="catatanLainTambahan"></textarea>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tr style="height: 100px">
                    <td>Nama Peninjau</td>
                    <td>Tanggal Tanda Tangan Peninjau</td>
                    <td>Komentar</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
