<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id"],
    data() {
        return {
            konfig_skema: [],
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            keterangan: "",
            latar_belakang: [],
            ruang_lingkup: [],
            tujuan_sertifikasi :[],
            acuan_normatif : [],
            kemasan : [],
            persyaratan_dasar:[],            
            hak : [{
                pemohon : [{
                    isi_pemohon : "",
                }],
                kewajiban_pemegang_sertifikat : [{
                    isi_kewajiban_pemegang_sertifikat : "",
                }],
            }],
            biaya_sertifikasi : [],
            proses_sertifikasi : [{
                pendaftaran : [{
                    isi_pendaftaran : "",
                }],
                asesmen : [{
                    isi_asesmen : "",
                }],
                uji_kompetensi : [{
                    isi_uji_kompetensi : "",
                }],
                keputusan : [{
                    isi_keputusan : "",
                }],
                pencabutan_pembekuan : [{
                    isi_pencabutan_pembekuan : "",
                }],
                pemeliharaan_sertifikat : [{
                    isi_pemeliharaan_sertifikat : "",
                }],
                sertifikasi_ulang : [{
                    isi_sertifikasi_ulang : "",
                }],
                penggunaan_sertifikat : [{
                    isi_penggunaan_sertifikat : "",
                }],
                banding : [{
                    isi_banding : "",
                }],
            }],
        };
    },
    mounted() {
        this.getDataKonfigSkema();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfigSkema() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var keterangan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "keterangan");
                        var latar_belakang = response_data_fix.konfig_template.find((konfig) => konfig.remark === "latar_belakang");
                        var ruang_lingkup = response_data_fix.konfig_template.find((konfig) => konfig.remark === "ruang_lingkup");
                        var tujuan_sertifikasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "tujuan_sertifikasi");
                        var acuan_normatif = response_data_fix.konfig_template.find((konfig) => konfig.remark === "acuan_normatif");
                        var kemasan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "kemasan");
                        var persyaratan_dasar = response_data_fix.konfig_template.find((konfig) => konfig.remark === "persyaratan_dasar");
                        var hak = response_data_fix.konfig_template.find((konfig) => konfig.remark === "hak");
                        var biaya_sertifikasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "biaya_sertifikasi");
                        var proses_sertifikasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "proses_sertifikasi");

                        self.keterangan = JSON.parse(keterangan.meta_template);
                        self.latar_belakang = JSON.parse(latar_belakang.meta_template);
                        self.ruang_lingkup = JSON.parse(ruang_lingkup.meta_template);
                        self.tujuan_sertifikasi = JSON.parse(tujuan_sertifikasi.meta_template);
                        self.acuan_normatif = JSON.parse(acuan_normatif.meta_template);
                        self.kemasan = JSON.parse(kemasan.meta_template);
                        self.persyaratan_dasar = JSON.parse(persyaratan_dasar.meta_template);
                        self.hak = JSON.parse(hak.meta_template);
                        self.biaya_sertifikasi = JSON.parse(biaya_sertifikasi.meta_template);
                        self.proses_sertifikasi = JSON.parse(proses_sertifikasi.meta_template);

                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        keterangan: self.keterangan,
                        latar_belakang: self.latar_belakang,
                        ruang_lingkup: self.ruang_lingkup,
                        tujuan_sertifikasi : self.tujuan_sertifikasi,
                        acuan_normatif : self.acuan_normatif,
                        kemasan : self.kemasan,
                        persyaratan_dasar : self.persyaratan_dasar,
                        hak : self.hak,
                        biaya_sertifikasi : self.biaya_sertifikasi,
                        proses_sertifikasi : self.proses_sertifikasi,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // self.$router.push({ name: "konfigurasi-dokumen-muk" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addLatarBelakang () {
            this.latar_belakang.push({
                isi_latar: "",
            });
        },
        removeLatarBelakang (index) {
            this.latar_belakang.splice(index, 1);
        },
        addRuangLingkup(){
            this.ruang_lingkup.push({
                isi_ruang_lingkup: "",
            }); 
        },
        removeRuangLingkup(index){
            this.ruang_lingkup.splice(index, 1);
        },
        addTujuanSertifikasi(){
            this.tujuan_sertifikasi.push({
                isi_tujuan_sertifikasi: "",
            }); 
        },
        removeTujuanSertifikasi(index){
            this.tujuan_sertifikasi.splice(index, 1);
        },
        addAcuanNormatif(){
            this.acuan_normatif.push({
                isi_acuan_normatif: "",
            }); 
        },
        removeAcuanNormatif(index){
            this.acuan_normatif.splice(index, 1);
        },
        addKemasan(){
            this.kemasan.push({
                kode_unit : "",
                judul_unit: "",
            });
        },
        removeKemasan(index){
            this.kemasan.splice(index, 1);
        },
        addPersyaratanDasar(){
            this.persyaratan_dasar.push({
                isi_persyaratan_dasar: "",
            }); 
        },
        removePersyaratanDasar(index){
            this.persyaratan_dasar.splice(index, 1);
        },
        addHakPemohon(){
            this.hak[0].pemohon.push({
                isi_pemohon: "",
            });
        },
        removeHakPemohon(index){
            this.hak[0].pemohon.splice(index, 1);
        },
        addKewajibanPemegangSertifikat(){
            this.hak[0].kewajiban_pemegang_sertifikat.push({
                isi_kewajiban_pemegang_sertifikat: "",
            });
        },
        removeKewajibanPemegangSertifikat(index){
            this.hak[0].kewajiban_pemegang_sertifikat.splice(index, 1);
        },
        addBiayaSertifikasi(){
            this.biaya_sertifikasi.push({
                isi_biaya_sertifikasi: "",
            });
        },
        removeBiayaSertifikasi(index){
            this.biaya_sertifikasi.splice(index, 1);
        },
        addPendaftaran (){
            this.proses_sertifikasi[0].pendaftaran.push({
                isi_pendaftaran: "",
            });
        },
        removePendaftaran(index){
            this.proses_sertifikasi[0].pendaftaran.splice(index, 1);
        },
        addAsesmen (){
            this.proses_sertifikasi[0].asesmen.push({
                isi_asesmen: "",
            });
        },
        removeAsesmen(index){
            this.proses_sertifikasi[0].asesmen.splice(index, 1);
        },
        addKeputusan (){
            this.proses_sertifikasi[0].keputusan.push({
                isi_keputusan: "",
            });
        },
        removeKeputusan(index){
            this.proses_sertifikasi[0].keputusan.splice(index, 1);
        },
        addPemeliharaanSertifikat (){
            this.proses_sertifikasi[0].pemeliharaan_sertifikat.push({
                isi_pemeliharaan_sertifikat: "",
            });
        },
        removePemeliharaanSertifikat(index){
            this.proses_sertifikasi[0].pemeliharaan_sertifikat.splice(index, 1);
        },
        addSertifikasiUlang (){
            this.proses_sertifikasi[0].sertifikasi_ulang.push({
                isi_sertifikasi_ulang: "",
            });
        },
        removeSertifikasiUlang(index){
            this.proses_sertifikasi[0].sertifikasi_ulang.splice(index, 1);
        },
        addPenggunaanSertifikat (){
            this.proses_sertifikasi[0].penggunaan_sertifikat.push({
                isi_penggunaan_sertifikat: "",
            });
        },
        removePenggunaanSertifikat(index){
            this.proses_sertifikasi[0].penggunaan_sertifikat.splice(index, 1);
        },
        addBanding (){
            this.proses_sertifikasi[0].banding.push({
                isi_banding: "",
            });
        },
        removeBanding(index){
            this.proses_sertifikasi[0].banding.splice(index, 1);
        },
        addUjiKompetensi(){
            this.proses_sertifikasi[0].uji_kompetensi.push({
                isi_uji_kompetensi: "",
            });
        },
        removeUjiKompetensi(index){
            this.proses_sertifikasi[0].uji_kompetensi.splice(index, 1);
        },
        addPembekuanPencabutan(){
            this.proses_sertifikasi[0].pencabutan_pembekuan.push({
                isi_pencabutan_pembekuan: "",
            });
        },
        removePembekuanPencabutan(index){
            this.proses_sertifikasi[0].pencabutan_pembekuan.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md ml-2">
            <h3 class="text-center">Skema Sertifikasi</h3>
        </div>
        <div class="col-md-2">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
        
        <div class="col-12">
            <h3 class="text-center">
                SKEMA SERTIFIKASI OKUPASI PELAKSANA PATOLOGI DAN TOKSIKOLOGI
            </h3>
        </div>

        <div class="col-12 mt-4">
            <label for="keterangan">keterangan</label>
            <textarea class="form-control" id="keterangan" rows="3" v-model="keterangan"></textarea>
        </div>
        <div class="col-12 mt-2">
            <table class="table table-responsive table-sm table-bordered">
                <tr>
                    <td>Disahkan Tanggal</td>
                    <td>: 31 Mei 2023</td>
                </tr>
                <tr>
                    <td>Oleh</td>
                    <td>: </td>
                </tr>
            </table>
        </div>
        <div class="col-12 mt-2">
            <table class="table table-responsive table-sm table-bordered">
                <tr>
                    <td>Nomor Dokumen</td>
                    <td>: BIO-108/LSPHBF/V/2023</td>
                </tr>
                <tr>
                    <td>Nomor Salinan</td>
                    <td>: </td>
                </tr>
                <tr>
                    <td rowspan="2">
                        Status Distribusi
                    </td>
                    <td>:</td>
                    <td>
                        <input type="checkbox" name="" id="" /> Terkendali
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <input type="checkbox" name="" id="" /> Tak Terkendali
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>1.LATAR BELAKANG </th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addLatarBelakang"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_latar, key_latar) in latar_belakang" :key="key_latar">
                        <td>
                            <input type="text" class="form-control" v-model="val_latar.isi_latar" placeholder="isi Latar Belakang" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeLatarBelakang(key_latar)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>2. RUANG LINGKUP SKEMA SERTIFIKASI</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addRuangLingkup"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_ruang, key_ruang) in ruang_lingkup" :key="key_ruang">
                        <td>
                            <input type="text" class="form-control" v-model="val_ruang.isi_ruang_lingkup" placeholder="isi Ruang Lingkup" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeRuangLingkup(key_ruang)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>3. TUJUAN SERTIFIKASI</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addTujuanSertifikasi"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_tujuan, key_tujuan) in tujuan_sertifikasi" :key="key_tujuan">
                        <td>
                            <input type="text" class="form-control" v-model="val_tujuan.isi_tujuan_sertifikasi" placeholder="isi Tujuan Sertifikasi" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeTujuanSertifikasi(key_tujuan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>4. ACUAN NORMATIF</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addAcuanNormatif"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_acuan, key_acuan) in acuan_normatif" :key="key_acuan">
                        <td>
                            <input type="text" class="form-control" v-model="val_acuan.isi_acuan_normatif" placeholder="isi Acuan Normatif" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeAcuanNormatif(key_acuan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>5. KEMASAN / PAKET KOMPETENSI</th>
                    </tr>
                </thead>
            </table>

            <ol>
                <li style="font-size: 12;font-weight:normal;text-align:justify;">
                    Jenis Skema : KKNI/Okupasi/Klaster
                </li>
                <li style="font-size: 12;font-weight:normal;text-align:justify;">
                    Nama Skema : Pelaksana Patologi dan Toksikologi
                </li>
            </ol>
        </div>

        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>NO</th>
                        <th>KODE UNIT</th>
                        <th>JUDUL UNIT KOMPETENSI</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addKemasan"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_kemasan, key_kemasan) in kemasan" :key="key_kemasan">
                        <td>
                            <input type="text" class="form-control" v-model="val_kemasan.kode_unit" placeholder="Kode Unit" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val_kemasan.judul_unit" placeholder="Judul Unit" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeKemasan(key_kemasan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>6. PERSYARATAN DASAR PEMOHON SERTIFIKASI</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addPersyaratanDasar"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_persyaratan, key_persyaratan) in persyaratan_dasar" :key="key_persyaratan">
                        <td>
                            <input type="text" class="form-control" v-model="val_persyaratan.isi_persyaratan_dasar" placeholder="isi Persyaratan Dasar" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removePersyaratanDasar(key_persyaratan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>7. HAK PEMOHON SERTIFIKASI DAN KEWAJIBAN PEMEGANG SERTIFIKAT</th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr>
                        <td class="ml-2">7.1 Hak Pemohon</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addHakPemohon"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_pemohon, key_pemohon) in hak[0].pemohon" :key="key_pemohon">
                        <td>
                            <input type="text" class="form-control" v-model="val_pemohon.isi_pemohon" placeholder="isi Hak Pemohon" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeHakPemohon(key_pemohon)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">7.2 Kewajiban Pemegang Sertifikat</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addKewajibanPemegangSertifikat"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_kewajiban, key_kewajiban) in hak[0].kewajiban_pemegang_sertifikat" :key="key_kewajiban">
                        <td>
                            <input type="text" class="form-control" v-model="val_kewajiban.isi_kewajiban_pemegang_sertifikat" placeholder="isi Kewajian Pemegang Sertifikat" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeKewajibanPemegangSertifikat(key_kewajiban)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>8 . BIAYA SERTIFIKASI</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addBiayaSertifikasi"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr v-for="(val_biaya, key_biaya) in biaya_sertifikasi" :key="key_biaya">
                        <td>
                            <input type="text" class="form-control" v-model="val_biaya.isi_biaya_sertifikasi" placeholder="isi Biaya Sertifikasi" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeBiayaSertifikasi(key_biaya)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr class="background-grey">
                        <th>9. PROSES SERTIFIKASI</th>
                    </tr>
                
                </thead>
                <tbody>
                    <tr>
                        <td class="ml-2">9.1 Proses Pendaftaran</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addPendaftaran"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_pendaftaran, key_pendaftaran) in proses_sertifikasi[0].pendaftaran" :key="key_pendaftaran">
                        <td>
                            <input type="text" class="form-control" v-model="val_pendaftaran.isi_pendaftaran" placeholder="isi Proses Pendaftaran" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removePendaftaran(key_pendaftaran)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.2 Proses Asesmen</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addAsesmen"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_asesmen, key_asesmen) in proses_sertifikasi[0].asesmen" :key="key_asesmen">
                        <td>
                            <input type="text" class="form-control" v-model="val_asesmen.asesmen" placeholder="isi Proses Asesmen" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeAsesmen(key_asesmen)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.3 Proses Uji Kompetensi</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addUjiKompetensi"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_uji_kompetensi, key_uji_kompetensi) in proses_sertifikasi[0].uji_kompetensi" :key="key_uji_kompetensi">
                        <td>
                            <input type="text" class="form-control" v-model="val_uji_kompetensi.isi_uji_kompetensi" placeholder="isi Proses Uji Kompetensi" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeUjiKompetensi(key_uji_kompetensi)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.4 Keputusan</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addKeputusan"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_keputusan, key_keputusan) in proses_sertifikasi[0].keputusan" :key="key_keputusan">
                        <td>
                            <input type="text" class="form-control" v-model="val_keputusan.isi_keputusan" placeholder="isi Proses Keputusan" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeKeputusan(key_keputusan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.5 Pembekuan dan Pencabutan Sertifikat</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addPembekuanPencabutan"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_pencabutan_pembekuan, key_pencabutan_pembekuan) in proses_sertifikasi[0].pencabutan_pembekuan" :key="key_pencabutan_pembekuan">
                        <td>
                            <input type="text" class="form-control" v-model="val_pencabutan_pembekuan.isi_pencabutan_pembekuan" placeholder="isi Proses Pembekuan dan Pencabutan Sertifikat" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removePembekuanPencabutan(key_pencabutan_pembekuan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.6 Surveilans pemegang sertifikat / Pemeliharaan Sertifikat </td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addPemeliharaanSertifikat"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_pemeliharaan, key_pemeliharaan) in proses_sertifikasi[0].pemeliharaan_sertifikat" :key="key_pemeliharaan">
                        <td>
                            <input type="text" class="form-control" v-model="val_pemeliharaan.isi_pemeliharaan_sertifikat" placeholder="isi Proses Pemeliharaan" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removePemeliharaanSertifikat(key_pemeliharaan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.7 Proses Sertifikasi Ulang</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addSertifikasiUlang"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_sertifikasi, key_sertifikasi) in proses_sertifikasi[0].sertifikasi_ulang" :key="key_sertifikasi">
                        <td>
                            <input type="text" class="form-control" v-model="val_sertifikasi.isi_sertifikasi_ulang" placeholder="isi Proses Sertifikasi Ulang" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removePemeliharaanSertifikat(key_pemeliharaan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.8 Penggunaan Sertifikat</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addSertifikasiUlang"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="2"><p class="ml-2">Pemegang sertifikat harus menandatangani persetujuan untuk:</p></td>
                    </tr>
                    <tr v-for="(val_sertifikasi, key_sertifikasi) in proses_sertifikasi[0].sertifikasi_ulang" :key="key_sertifikasi">
                        <td>
                            <input type="text" class="form-control" v-model="val_sertifikasi.isi_sertifikasi_ulang" placeholder="isi Proses Sertifikasi Ulang" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removePemeliharaanSertifikat(key_pemeliharaan)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ml-2">9.9 Banding</td>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" @click="addBanding"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr v-for="(val_banding, key_banding) in proses_sertifikasi[0].banding" :key="key_banding">
                        <td>
                            <input type="text" class="form-control" v-model="val_key_banding.isi_banding" placeholder="isi Proses Banding" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" @click="removeBanding(key_banding)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" @click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
        
</template>
<style scoped>
.ml-2 {
    margin-left: 2rem;
}
</style>
