<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id"],
    data() {
        return {
            mapa: [],
            keterangan: [
                {
                    isi_keterangan: null,
                },
            ],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_mapa = response_data_fix.konfig_template.find((konfig) => konfig.remark === "mapa");
                        self.mapa = JSON.parse(meta_mapa.meta_template);
                        var meta_keterangan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "keterangan");
                        self.keterangan = JSON.parse(meta_keterangan.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        mapa: self.mapa,
                        keterangan: self.keterangan,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // self.$router.push({ name: "konfigurasi-dokumen-muk" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addMapa() {
            this.mapa.push({ isi_mapa: null });
        },
        removeMapa(index) {
            this.mapa.splice(index, 1);
        },
        addKeterangan() {
            this.keterangan.push({ isi_keterangan: null });
        },
        removeKeterangan(index) {
            this.keterangan.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.MAPA.02- PETA INSTRUMEN ASESSMEN HASIL PENDEKATAN ASESMEN DAN PERENCANAAN ASESMEN*</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tbody>
                <tr>
                    <td rowspan="2">
                        Skema Sertifikasi <br />
                        (KKNI/Okupasi/Klaster)
                    </td>
                    <td>Judul</td>
                    <td style="width: 550px">:</td>
                </tr>
                <tr>
                    <td>Nomor</td>
                    <td>:</td>
                </tr>
            </tbody>
        </table>
        <p style="text-align: left">*Coret yang tidak perlu</p>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <thead class="text-center" style="vertical-align: middle">
                <tr>
                    <th rowspan="2" style="width: 50px">No</th>
                    <th rowspan="2">MUK</th>
                    <th colspan="5">Potensi Asesi **</th>
                    <td rowspan="2" style="width: 50px">
                        <div class="btn btn-success btn-sm" v-on:click="addMapa"><i class="bx bx-plus"></i></div>
                    </td>
                </tr>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                </tr>
            </thead>
            <tbody class="text-center" style="vertical-align: middle">
                <tr v-for="(val, key) in mapa" :key="key">
                    <td>{{ key + 1 }}</td>
                    <td class="text-start">
                        <input type="text" class="form-control" v-model="val.isi_mapa" />
                    </td>
                    <td><input type="checkbox" /></td>
                    <td><input type="checkbox" /></td>
                    <td><input type="checkbox" /></td>
                    <td><input type="checkbox" /></td>
                    <td><input type="checkbox" /></td>
                    <td>
                        <div class="btn btn-danger btn-sm" v-on:click="removeMapa(key)"><i class="bx bx-minus"></i></div>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style="text-align: left">*) diisi berdasarkan hasil penentuan pendekatan asesmen dan perencanaan asesmen</p>
    </div>
    <div class="row">
        <div class="col-12">
            <p style="text-align: left">**) Keterangan</p>
            <ol>
                <li v-for="(val, key) in keterangan" :key="key">
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="val.isi_keterangan" />
                        </div>
                        <div class="col-1" v-if="key == 0">
                            <div class="btn btn-success btn-sm" v-on:click="addKeterangan"><i class="bx bx-plus"></i></div>
                        </div>
                        <div class="col-1" v-else>
                            <div class="btn btn-danger btn-sm" v-on:click="removeKeterangan(key)"><i class="bx bx-minus"></i></div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
