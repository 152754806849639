<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            tujuan_asesmen: [],
            unit_kompetensi: [
                {
                    isi_kode_unit: null,
                    isi_judul_unit: null,
                    isi_jenis_standar: null
                }
            ],
            persyaratan: [
                {
                    isi_bukti_persyaratan: null,
                    isi_tidak_ada: null,
                }
            ],
            data_pribadi: [
                {
                    isi_data_pribadi: null,
                },
            ],
            data_pekerjaan: [
                {
                    isi_data_pekerjaan: null,
                },
            ],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_tujuan_asesmen = response_data_fix.konfig_template.find((konfig) => konfig.remark === "tujuan_asesmen");
                        self.tujuan_asesmen = JSON.parse(meta_tujuan_asesmen.meta_template);
                        var meta_unit_kompetensi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "unit_kompetensi");
                        self.unit_kompetensi = JSON.parse(meta_unit_kompetensi.meta_template);
                        var meta_persyaratan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "persyaratan");
                        self.persyaratan = JSON.parse(meta_persyaratan.meta_template);
                        var meta_data_pribadi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "data_pribadi");
                        self.data_pribadi = JSON.parse(meta_data_pribadi.meta_template);
                        var meta_data_pekerjaan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "data_pekerjaan");
                        self.data_pekerjaan = JSON.parse(meta_data_pekerjaan.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        tujuan_asesmen: self.tujuan_asesmen,
                        unit_kompetensi: self.unit_kompetensi,
                        persyaratan: self.persyaratan,
                        data_pribadi: self.data_pribadi,
                        data_pekerjaan: self.data_pekerjaan
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addTujuanAsesmen() {
            this.tujuan_asesmen.push({ isi_tujuan_asesmen: null });
        },
        removeTujuanAsesmen(index) {
            this.tujuan_asesmen.splice(index, 1);
        },
        addUnitKompetensi() {
            this.unit_kompetensi.push({
                    isi_kode_unit: null,
                    isi_judul_unit: null,
                    isi_jenis_standar: null
                });
        },
        removeUnitKompetensi(index) {
            this.unit_kompetensi.splice(index,1);
        },
        addPersyaratan() {
            this.persyaratan.push({
                    isi_bukti_persyaratan: null,
                    isi_tidak_ada: null,
                });
        },
        removePersyaratan(index) {
            this.persyaratan.splice(index,1);
        },
        addPribadi() {
            this.data_pribadi.push({ isi_data_pribadi: null });
        },
        removePribadi(index) {
            this.data_pribadi.splice(index, 1);
        },
        addPekerjaan() {
            this.data_pekerjaan.push({ isi_data_pekerjaan: null });
        },
        removePekerjaan(index) {
            this.data_pekerjaan.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.APL.01. PERMOHONAN SERTIFIKASI KOMPETENSI</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <h6>Bagian 1 :  Rincian Data Pemohon Sertifikasi</h6>
    <p>Pada bagian ini,  cantumkan data pribadi, data pendidikan formal serta data pekerjaan anda pada saat ini.</p>
    <div class="row">
        <div class="col-12">
            <p class="font-bold">a. Data Pribadi</p>
            <ul>
                <li v-for="(val, key) in data_pribadi" :key="key">
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="val.isi_data_pribadi" />
                        </div>
                        <div class="col-1" v-if="key == 0">
                            <div class="btn btn-success btn-sm" v-on:click="addPribadi()"><i class="bx bx-plus"></i></div>
                        </div>
                        <div class="col-1" v-else>
                            <div class="btn btn-danger btn-sm" v-on:click="removePribadi(key)"><i class="bx bx-minus"></i></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <p>*Coret yang tidak perlu</p>
    <div class="row">
        <div class="col-12">
            <p class="font-bold" >b. Data Pekerjaan Sekarang</p>
            <ul>
                <li v-for="(val, key) in data_pekerjaan" :key="key">
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="val.isi_data_pekerjaan" />
                        </div>
                        <div class="col-1" v-if="key == 0">
                            <div class="btn btn-success btn-sm" v-on:click="addPekerjaan()"><i class="bx bx-plus"></i></div>
                        </div>
                        <div class="col-1" v-else>
                            <div class="btn btn-danger btn-sm" v-on:click="removePekerjaan(key)"><i class="bx bx-minus"></i></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <h6>Bagian  2 :  Data Sertifikasi</h6>
    <p>Tuliskan Judul dan Nomor Skema Sertifikasi yang anda ajukan berikut Daftar Unit Kompetensi sesuai kemasan pada skema sertifikasi untuk mendapatkan pengakuan sesuai dengan latar belakang pendidikan, pelatihan serta pengalaman kerja yang anda miliki.</p>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>
                            Tujuan Asesmen
                        </td>
                        <td></td>
                        <td>
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addTujuanAsesmen"><i class="bx bx-plus"></i></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <ul>
                                <li v-for="(val, key) in tujuan_asesmen" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="val.isi_tujuan_asesmen" />
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removeTujuanAsesmen(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <h6>Daftar Unit Kompetensi sesuai kemasan: </h6>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Kode Unit</th>
                        <th class="text-center">Judul Unit</th>
                        <th class="text-center">Jenis Standar (Standar Khusus/Standar Internasional/SKKNI)</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addUnitKompetensi"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in unit_kompetensi" :key="key">
                        <td class="text-center">{{ key + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_kode_unit" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_judul_unit" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_jenis_standar" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeUnitKompetensi(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <h6>Bagian  3  :  Bukti Kelengkapan Pemohon Bukti Persyaratan Dasar Pemohon </h6>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" rowspan="2">No</th>
                        <th class="text-center" rowspan="2">Bukti Persyaratan Dasar</th>
                        <th class="text-center" colspan="2">Ada</th>
                        <th class="text-center" rowspan="2">Tidak Ada</th>
                        <th style="width: 50px" rowspan="2">
                            <div class="btn btn-success btn-sm" v-on:click="addPersyaratan"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-center">Memenuhi Syarat</th>
                        <th class="text-center">Tidak Memenuhi Syarat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in persyaratan" :key="key">
                        <td class="text-center">{{ key + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_bukti_persyaratan" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_tidak_ada" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removePersyaratan(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="3"  style="width:60%">
                            Rekomendasi (diisi oleh LSP): <br>
                            Berdasarkan ketentuan persyaratan dasar, maka pemohon: 
                            Diterima/ Tidak diterima *) sebagai peserta  sertifikasi <br>
                            * coret yang tidak sesuai
                        </td>
                        <td colspan="2">Pemohon/ Kandidat :</td>
                    </tr>
                    <tr>
                        <td style="width:200px">Nama</td>
                        <td>: </td>
                    </tr>
                    <tr>
                        <td style="height: 80px;">Tanda tangan/Tanggal</td>
                        <td>: </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="4" style="width:60%">
                            Catatan : <br><br>

                            Mohon dilampirkan : <br>
                            - Fotokopi KTP/KK/SIM*) sebanyak 1 lembar <br>
                            - Pas foto 3x4 sebanyak 2 lembar <br>
                            *) coret yang tidak sesuai <br>

                        </td>
                        <td colspan="2">Admin LSP :</td>
                    </tr>
                    <tr>
                        <td style="width:200px">Nama</td>
                        <td>: </td>
                    </tr>
                    <tr>
                        <td>No Reg</td>
                        <td>: </td>
                    </tr>
                    <tr>
                        <td style="height: 80px;">Tanda tangan/Tanggal</td>
                        <td>: </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>

<style scoped>
    .font-bold {
        font-weight: bold;
        text-align: left
    }
</style>
