<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            daftar_hadir: [
                {
                    isi_nama: null,
                },
            ],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_daftar_hadir = response_data_fix.konfig_template.find((konfig) => konfig.remark === "daftar_hadir");
                        self.daftar_hadir = JSON.parse(meta_daftar_hadir.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        daftar_hadir: self.daftar_hadir,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addDaftarHadir() {
            this.daftar_hadir.push({ isi_nama: null });
        },
        removeDaftarHadir(index) {
            this.daftar_hadir.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3><u>DAFTAR HADIR ASESMEN</u></h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <table>
            <tr>
                <td><span style="font-size: medium; font-weight: bold">HARI/TANGGAL :</span></td>
            </tr>
            <tr>
                <td><span style="font-size: medium; font-weight: bold">WAKTU :</span></td>
            </tr>
        </table>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <thead class="text-center" style="vertical-align: middle">
                <tr>
                    <th style="width: 50px">No</th>
                    <th>NAMA</th>
                    <th>TANDA TANGAN</th>
                    <th>KETERANGAN</th>
                    <td style="width: 50px">
                        <div class="btn btn-success btn-sm" v-on:click="addDaftarHadir()"><i class="bx bx-plus"></i></div>
                    </td>
                </tr>
            </thead>
            <tbody class="text-center" style="vertical-align: middle">
                <tr v-for="(val, key) in daftar_hadir" :key="key">
                    <td>{{ key + 1 }}</td>
                    <td class="text-start">
                        <input type="text" class="form-control" v-model="val.isi_nama" />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <div class="btn btn-danger btn-sm" v-on:click="removeDaftarHadir(key)"><i class="bx bx-minus"></i></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
