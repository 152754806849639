<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            konfig_skema: [],
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            panduan: [],
        };
    },
    mounted() {
        this.getDataKonfigSkema();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfigSkema() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-konfig-skema",
                params: {
                    skema_id: self.skema_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.konfig_skema = response_data_fix.referensi[0];
                        console.log(response_data_fix.referensi[0]);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id + "&skema_id=" + self.skema_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        panduan: self.panduan,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        // function untuk configurasi dokumen
        addPanduan() {
            this.panduan.push({ isi_panduan: null });
        },
        removePanduan(index) {
            this.panduan.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.APL.02. ASESMEN MANDIRI</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tr>
                <td rowspan="2">
                    Skema Sertifikasi <br />
                    (KKNI/Okupasi/Klaster)
                </td>
                <td>Judul</td>
                <td>: PELAKSANA PATOLOGI DAN TOKSIKOLOGI</td>
            </tr>
            <tr>
                <td>Nomor</td>
                <td>: BIO-108/LSPHBF/V/2023</td>
            </tr>
        </table>
        <p style="text-align: left">*Coret yang tidak perlu</p>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tbody>
                <tr style="background-color: rgb(250, 192, 144)">
                    <th>
                        PANDUAN ASESMEN MANDIRI
                        <div class="float-end">
                            <div class="btn btn-success btn-sm" v-on:click="addPanduan()"><i class="bx bx-plus"></i></div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <ul>
                            <li v-for="(val, key) in panduan" :key="key">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" v-model="val.isi_panduan" />
                                    </div>
                                    <div class="col-1">
                                        <div class="btn btn-danger btn-sm" v-on:click="removePanduan(key)"><i class="bx bx-minus"></i></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm table-bordered" v-for="(val_unit, key_unit) in konfig_skema.konfig_unit_kompetensi" :key="key_unit">
            <thead>
                <tr>
                    <td style="width: 500px">Unit Kompetensi {{ key_unit + 1 }}:</td>
                    <td colspan="3">
                        {{ val_unit.unit_kom_nama }}
                    </td>
                </tr>
                <tr>
                    <td>Dapatkah Saya ................?</td>
                    <td>K</td>
                    <td>BK</td>
                    <td>Bukti yang relevan</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(val_elemen, key_elemen) in val_unit.konfig_elemen" :key="key_elemen">
                    <td>
                        {{ key_elemen + 1 }}. Elemen: {{ val_elemen.elemen_kom_nama }} <br />
                        Kriteria Unjuk Kerja: <br />
                        <ol>
                            <li v-for="(val_kuk, key_kuk) in val_elemen.konfig_kuk" :key="key_kuk">
                                {{ val_kuk.kuk_nama }}
                            </li>
                        </ol>
                    </td>
                    <td><input type="checkbox" /></td>
                    <td><input type="checkbox" /></td>
                    <td>
                        <input type="checkbox" />Curriculum Vitae dari Perusahaan <br />
                        <input type="checkbox" />Personal Qualification dari Perusahaan <br />
                        <input type="checkbox" />Kartu Kompetensi Karyawan dari Perusahaan <br />
                        <input type="checkbox" />Laporan On Job Training (OJT) dari Perusahaan <br />
                        <input type="checkbox" />......... <br />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <tr style="height: 100px">
                <td>Nama Asesi</td>
                <td>Tanggal</td>
                <td>Tanda Tangan Asesi:</td>
            </tr>
            <tr>
                <th colspan="3">Ditinjau oleh Asesor:</th>
            </tr>
            <tr style="height: 100px">
                <td>Nama Asesi</td>
                <td>
                    Rekomendasi: <br />
                    Asesmen dapat dilanjutkan/ tidak dapat dilanjutkan
                </td>
                <td>Tanda Tangan dan Tanggal:</td>
            </tr>
        </table>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
