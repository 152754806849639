<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            konfig_skema: [],
            skema_id: "cee97a80-3757-11ee-9cda-0242ac110008",
            pendekatan_asesmen: [],
            langkah: [],
            konfirmasi : [],
            penyusun : [],
            konteks_asesmen: [{judul : "Kontesk Asesmen", hal : [{judul_hal : ""}]}],
            unit_kompetensi: [ 
                {
                    unit_kode: "BIO-108/LSPHBF/V/2023",
                    unit_kom_nama: "Menerapkan Keselamatan, Kesehatan Kerja (K3) dan Lingkungan di Areal Biopharmaceutical",
                    rencana_asesmen : [{
                        judul :  "Melakukan identifikasi aspek penting dan penilaian resiko serta pengendalian risiko K3 dan Lingkungan.",
                        elemen_kompetensi: [
                            {
                                judul_kinerja :  "Alat dan bahan penunjang disiapkan sesuai prosedur.",
                                bukti:[]
                            }
                        ]
                    }]
                },
                {
                    unit_kode: "BIO-108/LSPHBF/V/2023",
                    unit_kom_nama: "Menerapkan Keselamatan, Kesehatan Kerja (K3) dan Lingkungan di Areal Biopharmaceutical",
                    rencana_asesmen : [{
                        judul :  "Melakukan identifikasi aspek penting dan penilaian resiko serta pengendalian risiko K3 dan Lingkungan.",
                        elemen_kompetensi: [
                            {
                                judul_kinerja :  "Alat dan bahan penunjang disiapkan sesuai prosedur.",
                                bukti:[]
                            }
                        ]
                    }]
                }
             ],
            persyaratan :[],
            
        };
    },
    mounted() {
        this.getDataKonfigSkema();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfigSkema() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var pendekatan_asesmen = response_data_fix.konfig_template.find((konfig) => konfig.remark === "pendekatan_asesmen");
                        var unit_kompetensi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "unit_kompetensi");
                        var persyaratan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "persyaratan");
                        var konfirmasi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "konfirmasi");
                        var penyusun = response_data_fix.konfig_template.find((konfig) => konfig.remark === "penyusun");
                        var konteks_asesmen = response_data_fix.konfig_template.find((konfig) => konfig.remark === "konteks_asesmen");

                        self.pendekatan_asesmen = JSON.parse(pendekatan_asesmen.meta_template);
                        self.unit_kompetensi = JSON.parse(unit_kompetensi.meta_template);
                        self.persyaratan = JSON.parse(persyaratan.meta_template);
                        self.konfirmasi = JSON.parse(konfirmasi.meta_template);
                        self.penyusun = JSON.parse(penyusun.meta_template);
                        self.konteks_asesmen = JSON.parse(konteks_asesmen.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    meta: JSON.stringify({
                        pendekatan_asesmen : self.pendekatan_asesmen,
                        unit_kompetensi: self.unit_kompetensi,
                        persyaratan : self.persyaratan,
                        konfirmasi : self.konfirmasi,
                        penyusun : self.penyusun,
                        konteks_asesmen : self.konteks_asesmen,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPendekatan_asesmen() {
            this.pendekatan_asesmen.push({
                judul: "",
                hal: [{ isi_hal: "" }],
            });
        },
        removePendekatan_asesmen(index) {
            this.pendekatan_asesmen.splice(index, 1);
        },
        addHal(index_pendekatan) {
            this.pendekatan_asesmen[index_pendekatan].hal.push({ isi_hal: "" });
        },
        removeHal(index_pendekatan, index_hal) {
            this.pendekatan_asesmen[index_pendekatan].hal.splice(index_hal, 1);
        },
        addRencanaAsesmen(index_unit) {
            this.unit_kompetensi[index_unit].rencana_asesmen.push({
                judul: "",
                elemen_kompetensi: [{ judul_kinerja: "", bukti: [] }],
            });
        },
        removeRencanaAsesmen(index_unit, index_rencana) {
            this.unit_kompetensi[index_unit].rencana_asesmen.splice(index_rencana, 1);
        },
        addElementKompetensi(index_unit, index_rencana) {
            this.unit_kompetensi[index_unit].rencana_asesmen[index_rencana].elemen_kompetensi.push({
                judul_kinerja: "",
                bukti: [],
            });
        },
        removeElementKompetensi(index_unit, index_rencana, index_elemen) {
            this.unit_kompetensi[index_unit].rencana_asesmen[index_rencana].elemen_kompetensi.splice(index_elemen, 1);
        },
        addPersyaratan() {
            this.persyaratan.push({
                judul: "",
                keteranan: "",
            });
        },
        removePersyaratan(index_pertanyaan) {
            this.persyaratan.splice(index_pertanyaan, 1);
        },
        addKonfirmasi() {
            this.konfirmasi.push({
                nama: "",
            });
        },
        removeKonfirmasi(index_konfirmasi) {
            this.konfirmasi.splice(index_konfirmasi, 1);
        },        
        addPenyusun() {
            this.penyusun.push({ 
                nama_penyusun: null,
                jabatan: null, 
            });
        },
        removePenyusun(index) {
            this.penyusun.splice(index, 1);
        },
        addKonteks() {
            this.konteks_asesmen[0].hal.push({ judul_hal: "" });
            console.log(this.konteks_asesmen[0].hal);
        },
        removeKonteks(index) {
            this.konteks_asesmen[0].hal.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center">FR.MAPA.01 ( Merencanakan Aktivitas dan Proses Asesmen)</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" @click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-responsive table-sm table-bordered">
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>: PELAKSANA PATOLOGI DAN TOKSIKOLOGI</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>: BIO-108/LSPHBF/V/2023</td>
                    </tr>
                </table>
                <p style="text-align: left">*Coret yang tidak perlu</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-responsive table-sm table-bordered">
                    <thead>
                        <tr class="background-grey">
                            <th colspan="2">1. Menentukan Pendekatan Asesmen</th>
                            <th style="width: 50px">
                                <div class="btn btn-success btn-sm" @click="addPendekatan_asesmen"><i class="bx bx-plus"></i></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(val, key) in pendekatan_asesmen" :key="key">
                            <template v-if="val.judul != 'Konteks Asesment'">
                            <td>
                                <input type="text" class="form-control" v-model="val.judul" />
                            </td>
                            <td>
                                <div class="row" v-for="(val_hal, key_hal) in val.hal" :key="key_hal">
                                    <div class="col">
                                        <input type="text" class="form-control" v-model="val_hal.isi_hal" />
                                    </div>
                                    <div class="col-1" v-if="key_hal == 0">
                                        <div class="btn btn-success btn-sm" @click="addHal(key)"><i class="bx bx-plus"></i></div>
                                    </div>
                                    <div class="col-1" v-else>
                                        <div class="btn btn-danger btn-sm" @click="removeHal(key, key_hal)"><i class="bx bx-minus"></i></div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" @click="removeLangkah(key)"><i class="bx bx-minus"></i></div>
                            </td>
                            </template>
                            <template v-else>
                                <td>
                                    <input type="text" class="form-control" v-model="val.judul" placeholder="Konteks asesment" />
                                </td>
                                <td>
                                    <div class="row" v-for="(val_konteks_asesmen, key_konteks_asesmen) in val.hal" :key="key_konteks_asesmen">
                                        <div class="col">
                                            <input type="text" class="form-control"  v-model="val_konteks_asesmen.isi_hal" />
                                        </div>
                                        <div class="col-1" v-if="key_konteks_asesmen != 0">
                                            <div class="btn btn-danger btn-sm" @click="removeKonteks( key_konteks_asesmen)"><i class="bx bx-minus"></i></div>
                                        </div>
                                        <div class="col-1" v-else>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="btn btn-success btn-sm" @click="addKonteks()"><i class="bx bx-plus"></i></div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>  
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-responsive table-sm table-bordered">
                    <thead>
                        <tr class="background-grey">
                            <th colspan="2">2. Mempersiapkan Rencana Asesmen </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div class="row"  v-for="(val_unit, key_unit) in unit_kompetensi" :key="key_unit">
            <div class="col-12">
                <table class="table table-responsive table-sm table-bordered">
                        <tr>
                            <td rowspan="2" class="text-center">
                                Unit Kompetensi <br />
                                No . {{ key_unit + 1 }}
                            </td>
                            <td>Judul</td>
                            <td>{{ val_unit.unit_kode }}</td>
                        </tr>
                        <tr>
                            <td>Nomor</td>
                            <td>{{ val_unit.unit_kom_nama }}</td>
                        </tr>
                </table>
            </div>
            <div class="col-12">
                <table class="table table-responsive table-sm table-bordered">
                    <thead class="text-center">
                        <tr class="background-grey">
                            <th rowspan="2" style="width: 20%;" colspan="2">Kriteria Unjuk Kerja</th>
                            <th rowspan="2" class="text-center">Bukti-Bukti
                                    (Kinerja, Produk, Portofolio, dan / atau Hafalan) diidentifikasi berdasarkan 
                                    Kriteria Unjuk Kerja dan Pendekatan Asesmen.
                            </th>
                            <th colspan="3">Jenis Bukti</th>
                            <th colspan="6">Metode dan Perangkat Asesmen
                                    CL (Ceklis Observasi/ Lembar Periksa), DIT (Daftar Instruksi Terstruktur), DPL (Daftar Pertanyaan Lisan),
                                    DPT (Daftar Pertanyaan Tertulis), VP (Verifikasi Portofolio), CUP (Ceklis Ulasan Produk), PW (Pertanyaan Wawancara)
                            </th>
                            <th  rowspan="2">
                            </th>
                            <th  rowspan="2">
                                <div class="text-center">
                                    <div class="btn btn-success btn-sm" @click="addRencanaAsesmen(key_unit)"><i class="bx bx-plus"></i></div>
                                </div>
                            </th>
                        </tr>
                        <tr class="background-grey">
                            <td class="text-center">L</td>
                            <td class="text-center">TL</td>
                            <td class="text-center">T</td>
                            <td class="text-center" style="font-size: 12px;">Obsevasi langsung (kerja nyata/aktivitas waktu nyata di tempat kerja di kingkungan tempat kerja yang disimulasikan)</td>
                            <td class="text-center" style="font-size: 12px;">Kegiatan Terstruktur (latihan simulasi dan bermain peran, proyek,  presentasi, lembar kegiatan)</td>
                            <td class="text-center" style="font-size: 12px;">Tanya Jawab (pertanyaan tertulis, wawancara, asesmen diri, tanya jawab lisan, angket, ujian lisan atau tertulis)</td>
                            <td class="text-center" style="font-size: 12px;">Verifikasi  Portfolio (sampel pekerjaaan yang disusun oleh Asesi, produk dengan dokumentasi pendukung, bukti sejarah, jurnal atau buku catatan, informasi tentang pengalaman hidup)</td>
                            <td class="text-center" style="font-size: 12px;">Review produk (testimoni dan laporan dari atasan,  bukti pelatihan, otentikasi pencapaian sebelumnya, wawancara dengan atasan, atau rekan kerja)</td>
                            <!-- Lainnya : …………… -->
                            <td class="text-center" style="font-size: 12px;">Lainnya : ……………</td>

                        </tr>
                    </thead>
                    <tbody v-for="(val_rencana, key_rencana) in val_unit.rencana_asesmen" :key="key_rencana">
                        <tr>
                            <td colspan="12"><input type="text" class="form-control" v-model="val_rencana.judul" /></td>
                            <td>
                                <div class="text-center">
                                    <div class="btn btn-success btn-sm" @click="addElementKompetensi(key_unit, key_rencana)"><i class="bx bx-plus"></i></div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="btn btn-danger btn-sm" @click="removeRencanaAsesmen(key_unit, key_rencana)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                        <tr v-for="(val_elemen, key_elemen) in val_rencana.elemen_kompetensi" :key="key_elemen">
                            <td></td>
                            <td>
                                <input type="text" class="form-control" v-model="val_elemen.judul_kinerja" />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-center">
                                <div class="btn btn-danger btn-sm" @click="removeElementKompetensi(key_unit, key_rencana, key_elemen)"><i class="bx bx-minus"></i></div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="mt-2">Penyusun dan Validator</p>
                <table class="table table-responsive table-sm table-bordered">
                    <thead>
                        <tr class="background-grey">
                            <th colspan="2">3.  Mengidentifikasi Persyaratan Modifikasi dan Kontekstualisasi:</th>
                            <th style="width: 50px">
                                <div class="btn btn-success btn-sm" @click="addPersyaratan"><i class="bx bx-plus"></i></div>
                            </th>
                        </tr>
                    
                    </thead>
                    <tbody>
                        <tr v-for="(val_pertanyaan, key_pertanyaan) in persyaratan" :key="key_pertanyaan">
                            <td>
                                <input type="text" class="form-control" v-model="val_pertanyaan.judul" placeholder="Judul" />
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="val_pertanyaan.keteranan" placeholder="Keterangan" />
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" @click="removePersyaratan(key_pertanyaan)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-responsive table-sm table-bordered">
                    <thead>
                        <tr class="background-grey text-center">
                            <th style="width: 45%;">Orang yang relevan</th>
                            <th style="width: 50%;">Tandatangan</th>
                            <th style="width: 50px">
                                <div class="btn btn-success btn-sm" @click="addKonfirmasi"><i class="bx bx-plus"></i></div>
                            </th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr v-for="(val_konfirmasi, key_konfirmasi) in konfirmasi" :key="key_konfirmasi">
                            <td class="text-center">
                                <div class="row">
                                    <div class="col-1">
                                        <input type="checkbox" class="form-check-input" />
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" v-model="val_konfirmasi.nama" placeholder="Jabatan Penanda Tangan" />
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <div class="btn btn-danger btn-sm" @click="removeKonfirmasi(key_konfirmasi)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-4">
        <div class="col-12">
            <p class="mt-2">Penyusun dan Validator</p>
            <table class="table table-responsive table-sm table-bordered">
                <thead class="text-center">
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th>Nama</th>
                        <th>Jabatan</th>
                        <th>Tandatangan</th>
                        <th>
                            <div class="text-center">
                                <div class="btn btn-success btn-sm" v-on:click="addPenyusun()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val_penyusun, key_penyusun) in penyusun" :key="key_penyusun">
                        <td>
                            <input type="text" class="form-control" v-model="val_penyusun.nama_penyusun" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val_penyusun.jabatan" />
                        </td>
                        <td></td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removePenyusun(val_penyusun)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

        <div class="row">
            <div class="col">
                <hr class="mt-3" />
                <div class="btn w-100 btn-primary" @click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
            </div>
        </div>
            
        
    </div>
</template>
<style scoped>
    .background-grey {
        background-color: rgb(230, 230, 230);
    }

</style>
