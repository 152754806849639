<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            kelengkapan: [],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var kelengkapan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "kelengkapan");
                        self.kelengkapan = JSON.parse(kelengkapan.meta_template);
                        console.log(self.kelengkapan)
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            console.log(self.penyusunan_muk_id)
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        kelengkapan: self.kelengkapan,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addKelengkapan() {
            this.kelengkapan.push({
                judul_kelengkapan: null,
                daftar_dokumen: [{ nama_dokumen: null }],
            });
        },
        removeKelengkapan(index) {
            this.kelengkapan.splice(index, 1);
        },
        addDokumen(index_kelengkapan) {
            this.kelengkapan[index_kelengkapan].daftar_dokumen.push({
                nama_dokumen: null,
            });
        },
        removeDokumen(index_kelengkapan, index_dokumen) {
            this.kelengkapan[index_kelengkapan].daftar_dokumen.splice(index_dokumen, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3 class="text-center"><b>DAFTAR PERIKSA</b></h3>
            <h3 class="text-center"><b>KELENGKAPAN DOKUMEN</b></h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="table table-responsive table-sm table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">No</th>
                    <th class="text-center" rowspan="2">Jenis Dokumen</th>
                    <th class="text-center" colspan="2">Status *)</th>
                    <th class="text-center" rowspan="2">Revisi</th>
                    <td class="text-center" rowspan="2" style="width: 50px">
                        <div class="btn btn-success btn-sm" v-on:click="addKelengkapan"><i class="bx bx-plus"></i></div>
                    </td>
                </tr>
                <tr>
                    <th class="text-center">Ada</th>
                    <th class="text-center">Tidak</th>
                </tr>
            </thead>
            <tbody v-for="(val, key) in kelengkapan" :key="key">
                <tr style="background-color: rgb(250, 191, 143)">
                    <th colspan="5">
                        <input type="text" class="form-control form-sm" v-model="val.judul_kelengkapan" />
                    </th>
                    <td>
                        <div>
                            <div class="btn btn-danger btn-sm" v-on:click="removeKelengkapan(key)"><i class="bx bx-minus"></i></div>
                        </div>
                    </td>
                </tr>
                <tr v-for="(val_dokumen, key_dokumen) in val.daftar_dokumen" :key="key_dokumen">
                    <td class="text-center">{{ key_dokumen + 1 }}</td>
                    <td>
                        <input type="text" class="form-control" v-model="val_dokumen.nama_dokumen" />
                    </td>
                    <td class="text-center"><input type="radio" name="check1" id="" /></td>
                    <td class="text-center"><input type="radio" name="check1" id="" /></td>
                    <td></td>
                    <td>
                        <div v-if="key_dokumen == 0">
                            <div class="btn btn-success btn-sm" v-on:click="addDokumen(key)"><i class="bx bx-plus"></i></div>
                        </div>
                        <div v-else>
                            <div class="btn btn-danger btn-sm" v-on:click="removeDokumen(key, key_dokumen)"><i class="bx bx-minus"></i></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
