<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            pertanyaan: [],
            catatan: [],
            penyusun: [],
            alphabet: 'abcdefghijklmnopqrstuvwxyz'.split(''),
            kunciJawaban: [],

        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var meta_pertanyaan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "pertanyaan");
                        var meta_catatan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "catatan");
                        var meta_penyusun = response_data_fix.konfig_template.find((konfig) => konfig.remark === "penyusun");
                        var meta_kunciJawaban = response_data_fix.konfig_template.find((konfig) => konfig.remark === "kunciJawaban");

                        self.pertanyaan = JSON.parse(meta_pertanyaan.meta_template);
                        self.catatan = JSON.parse(meta_catatan.meta_template);
                        self.penyusun = JSON.parse(meta_penyusun.meta_template);
                        self.kunciJawaban = JSON.parse(meta_kunciJawaban.meta_template);

                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        indexToAlphabet(index) {
            return this.alphabet[index] || '';
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        pertanyaan : self.pertanyaan,
                        catatan : self.catatan,
                        penyusun : self.penyusun,
                        kunciJawaban : self.kunciJawaban,

                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        addPertanyaan() {
            this.pertanyaan.push({ 
                judul_pertanyaan: null,
                daftar_pertanyaan: [{ nama_pertanyaan: null }], 
            });
        },
        removePertanyaan(index) {
            this.pertanyaan.splice(index, 1);
        },
        addNamaPertanyaan(index_pertanyaan) {
            this.pertanyaan[index_pertanyaan].daftar_pertanyaan.push({
                nama_pertanyaan: null,
            });
        },
        removeNamaPertanyaan(index_pertanyaan, index_namapertanyaan) {
            this.pertanyaan[index_pertanyaan].daftar_pertanyaan.splice(index_namapertanyaan, 1);
        },
        
        addCatatan() {
            this.catatan.push({ isi_catatan: null });
        },
        removeCatatan(index) {
            this.catatan.splice(index, 1);
        },
        addPenyusun() {
            this.penyusun.push({ 
                nama_penyusun: null,
                jabatan: null, 
            });
        },
        removePenyusun(index) {
            this.penyusun.splice(index, 1);
        },
        addKunciJawaban() {
            this.kunciJawaban.push({ 
                isi_kunciJawaban: null,
            });
        },
        removeKunciJawaban(index) {
            this.kunciJawaban.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.IA.05 PERTANYAAN TERTULIS PILIHAN GANDA</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">TUK</td>
                        <td>: Sewaktu/Tempat Kerja/Mandiri*</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Tanggal</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Unit Kompetensi
                        </td>
                        <td>Kode Unit</td>
                        <td class="tableWidth">:</td>
                    </tr>
                    <tr>
                        <td>Judul Unit</td>
                        <td class="tableWidth">:</td>
                    </tr>
                   
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th colspan="4">
                            Jawab semua pertanyaan berikut:
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addPertanyaan()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(val, key) in pertanyaan" :key="key">
                    <tr style="background-color: rgb(250, 191, 143)">
                        <th colspan="3">
                            <input type="text" class="form-control form-sm" v-model="val.judul_pertanyaan" />
                        </th>
                        <td>
                            <div>
                                <div class="btn btn-danger btn-sm" v-on:click="removePertanyaan(key)"><i class="bx bx-minus"></i></div>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(val_pertanyaan, key_pertanyaan) in val.daftar_pertanyaan" :key="key_pertanyaan">
                        <td class="text-center">{{ indexToAlphabet(key_pertanyaan) }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="val_pertanyaan.nama_pertanyaan" />
                        </td>
                        <td></td>
                        <td>
                            <div v-if="key_pertanyaan == 0">
                                <div class="btn btn-success btn-sm" v-on:click="addNamaPertanyaan(key)"><i class="bx bx-plus"></i></div>
                            </div>
                            <div v-else>
                                <div class="btn btn-danger btn-sm" v-on:click="removeNamaPertanyaan(key, key_pertanyaan)"><i class="bx bx-minus"></i></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <th>
                            Catatan
                            <div class="float-end">
                                <div class="btn btn-success btn-sm" v-on:click="addCatatan()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(val, key) in catatan" :key="key">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="val.isi_catatan" />
                                        </div>
                                        <div class="col-1">
                                            <div class="btn btn-danger btn-sm" v-on:click="removeCatatan(key)"><i class="bx bx-minus"></i></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <p class="mt-2">Penyusun dan Validator</p>
            <table class="table table-responsive table-sm table-bordered">
                <thead class="text-center">
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th>Nama</th>
                        <th>Jabatan</th>
                        <th>Tandatangan</th>
                        <th>
                            <div class="text-center">
                                <div class="btn btn-success btn-sm" v-on:click="addPenyusun()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val_penyusun, key_penyusun) in penyusun" :key="key_penyusun">
                        <td>
                            <input type="text" class="form-control" v-model="val_penyusun.nama_penyusun" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val_penyusun.jabatan" />
                        </td>
                        <td></td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removePenyusun(val_penyusun)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md">
            <h3>FR.IA.05.B. LEMBAR KUNCI JAWABAN PERTANYAAN TERTULIS PILIHAN GANDA</h3>
        </div>
        
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">TUK</td>
                        <td>: Sewaktu/Tempat Kerja/Mandiri*</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Tanggal</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Unit Kompetensi
                        </td>
                        <td>Kode Unit</td>
                        <td class="tableWidth">:</td>
                    </tr>
                    <tr>
                        <td>Judul Unit</td>
                        <td class="tableWidth">:</td>
                    </tr>
                   
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="mt-2">Kunci Jawaban Pertanyaan Tertulis - Pilihan Ganda:</p>
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center">No</th>
                        <th>Jawaban</th>
                        <th style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addKunciJawaban"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in kunciJawaban" :key="key">
                        <td class="text-center">{{ key + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_kunciJawaban" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeKunciJawaban(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <p class="mt-2">Penyusun dan Validator</p>
            <table class="table table-responsive table-sm table-bordered">
                <thead class="text-center">
                    <tr style="background-color: rgb(230, 230, 230)">
                        <th>Nama</th>
                        <th>Jabatan</th>
                        <th>Tandatangan</th>
                        <th>
                            <div class="text-center">
                                <div class="btn btn-success btn-sm" v-on:click="addPenyusun()"><i class="bx bx-plus"></i></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val_penyusun, key_penyusun) in penyusun" :key="key_penyusun">
                        <td>
                            <input type="text" class="form-control" v-model="val_penyusun.nama_penyusun" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="val_penyusun.jabatan" />
                        </td>
                        <td></td>
                        <td class="text-center">
                            <div class="btn btn-danger btn-sm" v-on:click="removePenyusun(val_penyusun)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md">
            <h3>FR.IA.05.B. LEMBAR KUNCI JAWABAN PERTANYAAN TERTULIS PILIHAN GANDA</h3>
        </div>
        
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">TUK</td>
                        <td>: Sewaktu/Tempat Kerja/Mandiri*</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Tanggal</td>
                        <td>:</td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            Unit Kompetensi
                        </td>
                        <td>Kode Unit</td>
                        <td class="tableWidth">:</td>
                    </tr>
                    <tr>
                        <td>Judul Unit</td>
                        <td class="tableWidth">:</td>
                    </tr>
                   
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="mt-2">Kunci Jawaban Pertanyaan Tertulis – Pilihan Ganda:</p>
            <table class="table table-responsive table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" rowspan="2">No</th>
                        <th rowspan="2">Jawaban</th>
                        <th colspan="2" >Rekomendasi</th>
                        <th rowspan="2"  style="width: 50px">
                            <div class="btn btn-success btn-sm" v-on:click="addKunciJawaban"><i class="bx bx-plus"></i></div>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-center">K</th>
                        <th class="text-center">BK</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, key) in kunciJawaban" :key="key">
                        <td class="text-center">{{ key + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="val.isi_kunciJawaban" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td class="text-center">
                            <input type="checkbox" />
                        </td>
                        <td>
                            <div class="btn btn-danger btn-sm" v-on:click="removeKunciJawaban(key)"><i class="bx bx-minus"></i></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>

<style scoped>
    .tableWidth {
        width: 50%;
    }
</style>
