<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["dokumen_id", "penyusunan_muk_id"],
    data() {
        return {
            // bukti_dikumpulkan: [
            //     {
            //         isi_tipe_bukti: null,
            //         isi_bukti_dikumpulkan: null
            //     }
            // ],
            pra_persetujuan_asesor: [],
            pra_persetujuan_asesi: [],
        };
    },
    mounted() {
        this.getDataKonfig();
    },
    methods: {
        // Proses get konfig template dokumen muk
        getDataKonfig() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/get-konfig-template",
                params: {
                    dokumen_id: self.dokumen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        // var meta_bukti_dikumpulkan = response_data_fix.konfig_template.find((konfig) => konfig.remark === "bukti_dikumpulkan");
                        var meta_pra_persetujuan_asesor = response_data_fix.konfig_template.find((konfig) => konfig.remark === "pra_persetujuan_asesor");
                        var meta_pra_persetujuan_asesi = response_data_fix.konfig_template.find((konfig) => konfig.remark === "pra_persetujuan_asesi");
                        // self.panduan_pihak_ketiga = JSON.parse(meta_bukti_dikumpulkan.meta_template);
                        self.pertanyaan = JSON.parse(meta_pra_persetujuan_asesor.meta_template);
                        self.uraian = JSON.parse(meta_pra_persetujuan_asesi.meta_template);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        checkPreview() {
            let self = this;

            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + self.dokumen_id, "_blank");
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/update-konfig-template",
                data: {
                    dokumen_id: self.dokumen_id,
                    // Meta Konten Pada Dokumen
                    meta: JSON.stringify({
                        // bukti_dikumpulkan: self.bukti_dikumpulkan,
                        pra_persetujuan_asesor: self.pra_persetujuan_asesor,
                        pra_persetujuan_asesi: self.pra_persetujuan_asesi,
                    }),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Konfigurasi dokumen MUK Berhasil Disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            if(self.penyusunan_muk_id){
                                self.$router.push({ name: "detail-penyusunan", params: { id: self.penyusunan_muk_id } });
                            }
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        // function untuk configurasi dokumen
        // addBukti() {
        //     this.bukti_dikumpulkan.push({ isi_tipe_bukti: null, isi_bukti_dikumpulkan: null });
        // },
        // removeBukti(index) {
        //     this.bukti_dikumpulkan.splice(index, 1);
        // },
        addAsesor() {
            this.pra_persetujuan_asesor.push({ isi_persetujuan_asesor: null });
        },
        removeAsesor(index) {
            this.pra_persetujuan_asesor.splice(index, 1);
        },
        addAsesi() {
            this.pra_persetujuan_asesi.push({ isi_persetujuan_asesi: null });
        },
        removeAsesi(index) {
            this.pra_persetujuan_asesi.splice(index, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md">
            <h3>FR.AK.01. PERSETUJUAN ASESMEN DAN KERAHASIAAN</h3>
        </div>
        <div class="col-md-4">
            <div class="text-end">
                <div class="btn btn-success btn-sm" v-on:click="checkPreview"><i class="bx bx-file-find"></i> Preview Dokumen</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive table-sm table-bordered">
                <tbody>
                    <tr>
                        <td colspan="3">Persetujuan Asesmen ini untuk menjamin bahwa Asesi telah diberi arahan secara rinci tentang perencanaan dan proses asesmen</td>
                    </tr>
                    <tr>
                        <td rowspan="2">
                            Skema Sertifikasi <br />
                            (KKNI/Okupasi/Klaster)
                        </td>
                        <td>Judul</td>
                        <td>: PELAKSANA PATOLOGI DAN TOKSIKOLOGI</td>
                    </tr>
                    <tr>
                        <td>Nomor</td>
                        <td>: BIO-108/LSPHBF/V/2023</td>
                    </tr>
                    <tr>
                        <td colspan="2">TUK</td>
                        <td>: Sewaktu/Tempat Kerja/Mandiri*</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesor</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nama Asesi</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td colspan="2" rowspan="2">Bukti yang akan dikumpulkan</td>
                        <td><input type="checkbox" name="" id="" />TL : Verifikasi Portofolio <input type="checkbox" name="" id="" />L : Observasi Langsung</td>
                    </tr>
                    <tr>
                        <td>
                            <input type="checkbox" name="" id="" />T: Hasil Tes Tulis <br />
                            <input type="checkbox" name="" id="" />T: Hasil Tes Lisan <br />
                            <input type="checkbox" name="" id="" />T: Hasil Wawancara <br />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Pelaksanaan asesmen disepakati pada:</td>
                        <td>
                            <p style="text-align: left">Hari / tanggal:</p>
                            <p style="text-align: left">Waktu:</p>
                            <p style="text-align: left">TUK:</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-responsive table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    Asesor
                                                    <div class="float-end">
                                                        <div class="btn btn-success btn-sm" v-on:click="addAsesor()"><i class="bx bx-plus"></i></div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li v-for="(val, key) in pra_persetujuan_asesor" :key="key">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <input type="text" class="form-control" v-model="val.isi_persetujuan_asesor" />
                                                                </div>
                                                                <div class="col-1">
                                                                    <div class="btn btn-danger btn-sm" v-on:click="removeAsesor(key)"><i class="bx bx-minus"></i></div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-responsive table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    Asesi
                                                    <div class="float-end">
                                                        <div class="btn btn-success btn-sm" v-on:click="addAsesi()"><i class="bx bx-plus"></i></div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ul>
                                                        <li v-for="(val, key) in pra_persetujuan_asesi" :key="key">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <input type="text" class="form-control" v-model="val.isi_persetujuan_asesi" />
                                                                </div>
                                                                <div class="col-1">
                                                                    <div class="btn btn-danger btn-sm" v-on:click="removeAsesi(key)"><i class="bx bx-minus"></i></div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <p style="text-align: left">Tanda tangan Asesor : …………………………… Tanggal : ………………………………</p>
                            <p style="text-align: left">Tanda tangan Asesi : …………………………… Tanggal : ………………………………</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: left">*Coret yang tidak perlu</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr class="mt-3" />
            <div class="btn w-100 btn-primary" v-on:click="StoreData"><i class="bx bx-save"></i> Simpan Konfigurasi Dokumen MUK</div>
        </div>
    </div>
</template>
